import React, { Component } from 'react';
import axios from 'axios';
import VideoList from './VideoList';
import VideoPlayer from './VideoPlayer';
import './VideoGallery.css';

import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  DuplicateIcon,
  ViewListIcon,
  TruckIcon,
  LightningBoltIcon,
  HandIcon,
  FlagIcon,
  LightBulbIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';

class VideoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category0Urls: [
        'https://youtu.be/mCXKKf2rg64?si=ZsrGuQteHo2Wy5vj',
        'https://youtu.be/nFuBmZuQdgc?si=xXV-uB_D_RWSubYH',
        'https://youtu.be/7r77aIss3jI?si=NYOvk7lBIILzyMqI',
        'https://youtu.be/71STUnLk-K8?si=NSegVZ2K88LdtvwA',
        'https://youtu.be/3hu8caMy1qg?si=SWIjxU8zyyUZawNq',
        'https://youtu.be/9d9HHFRve2s?si=2oHlGB5ACvPuRsL_',
        'https://youtu.be/aFUgEiFBb-U?si=DB8LE3ji8SN385le',
        'https://youtu.be/2Eg6vgV3Qe8?si=oE5nmon7d836bWJ-',
        'https://youtu.be/Bxmsi0cspCw?si=zRO3kzi2VyuXvlUD',
        'https://youtu.be/5AEVGtmlBkQ?si=wtKHnpw3Xsti8neV',
        'https://youtu.be/PUb68qP1ySI?si=r12hNSZOQ1Z5PlWM',
        'https://youtu.be/Vnr7oyTRFy4?si=_RiM1Dp49BHJYgoY',
        'https://youtu.be/yMDC0X63sd0?si=Gb7kldgn1GOqhmkt',
        'https://youtu.be/ttw0L15ZoeY?si=mZCNdBbM_WLirS-M',
        'https://youtu.be/sendb1yMIls?si=1a1Yiyr5LY414XfT',
        'https://youtu.be/U-fQM9kpPA4?si=FGfeM0frF_5A3kDH',
        'https://youtu.be/r4GzAuE4eDM?si=eRre8PWgyRus1gPi',
        'https://youtu.be/56lvGdX_yUg?si=Lhbu2Edo_mCes9-B',
        'https://youtu.be/ZrjeyjXpW1s?si=h92BA0Lmigl9ACPQ',
        'https://youtu.be/rBoBNmQuEqY?si=vXKB2Shx-z-nwls6',
      ],
      category1Urls: [
        'https://youtu.be/mCXKKf2rg64?si=ZsrGuQteHo2Wy5vj',
      ],
      category2Urls: [
        'https://youtu.be/nFuBmZuQdgc?si=xXV-uB_D_RWSubYH',
        'https://youtu.be/7r77aIss3jI?si=NYOvk7lBIILzyMqI',
        'https://youtu.be/71STUnLk-K8?si=NSegVZ2K88LdtvwA',
      ],
      category3Urls: [
        'https://youtu.be/3hu8caMy1qg?si=SWIjxU8zyyUZawNq',
      ],
      category4Urls: [
        'https://youtu.be/aFUgEiFBb-U?si=DB8LE3ji8SN385le',
        'https://youtu.be/2Eg6vgV3Qe8?si=oE5nmon7d836bWJ-',
        'https://youtu.be/Bxmsi0cspCw?si=zRO3kzi2VyuXvlUD',
        'https://youtu.be/5AEVGtmlBkQ?si=wtKHnpw3Xsti8neV',
      ],
      category5Urls: [
        'https://youtu.be/9d9HHFRve2s?si=2oHlGB5ACvPuRsL_',
      ],
      category6Urls: [],
      category7Urls: [
        'https://youtu.be/Vnr7oyTRFy4?si=_RiM1Dp49BHJYgoY',
        'https://youtu.be/yMDC0X63sd0?si=Gb7kldgn1GOqhmkt',
        'https://youtu.be/ttw0L15ZoeY?si=mZCNdBbM_WLirS-M',
        'https://youtu.be/sendb1yMIls?si=1a1Yiyr5LY414XfT',

      ],
      category8Urls: [
        'https://youtu.be/PUb68qP1ySI?si=r12hNSZOQ1Z5PlWM',
      ],
      category9Urls: [
        'https://youtu.be/r4GzAuE4eDM?si=eRre8PWgyRus1gPi',
      ],
      category10Urls: [],
      category11Urls: [],
      category12Urls: [],
      category13Urls: [
        'https://youtu.be/U-fQM9kpPA4?si=FGfeM0frF_5A3kDH',
      ],
      category14Urls: [],
      category15Urls: [
        'https://youtu.be/56lvGdX_yUg?si=Lhbu2Edo_mCes9-B',
        'https://youtu.be/ZrjeyjXpW1s?si=h92BA0Lmigl9ACPQ',
        'https://youtu.be/rBoBNmQuEqY?si=vXKB2Shx-z-nwls6',
      ],
      category16Urls: [],
      category0Videos: [],
      category1Videos: [],
      category2Videos: [],
      category3Videos: [],
      category4Videos: [],
      category5Videos: [],
      category6Videos: [],
      category7Videos: [],
      category8Videos: [],
      category9Videos: [],
      category10Videos: [],
      category11Videos: [],
      category12Videos: [],
      category13Videos: [],
      category14Videos: [],
      category15Videos: [],
      category16Videos: [],
      selectedCategory: 'category1',
      selectedVideo: null,
    };
  }

  componentDidMount() {
    for (let i = 0; i <= 16; i++) {
      this.fetchVideoDetails(`category${i}Urls`, `category${i}Videos`);
    }
  }

  // fetchVideoDetails = async (urlStateKey, videoStateKey) => {
  //   const urls = this.state[urlStateKey];
  //   const videoDetailsPromises = urls.map(url =>
  //     axios.get(`https://www.youtube.com/oembed?url=${url}&format=json`)
  //       .then(response => response.data)
  //       .catch(error => {
  //         console.error('Error fetching video details:', error);
  //         return null;
  //       })
  //   );

  //   const videoDetails = await Promise.all(videoDetailsPromises);
  //   this.setState({ [videoStateKey]: videoDetails.filter(video => video) });
  // };

  fetchVideoDetails = async (urlStateKey, videoStateKey) => {
    const urls = this.state[urlStateKey];
    const videoDetailsPromises = urls.map(url =>
      axios.get(`https://www.youtube.com/oembed?url=${url}&format=json`)
        .then(response => ({
          ...response.data,
          url: url, // Include the URL in the video details
        }))
        .catch(error => {
          console.error('Error fetching video details:', error);
          return null;
        })
    );

    const videoDetails = await Promise.all(videoDetailsPromises);
    this.setState({ [videoStateKey]: videoDetails.filter(video => video) });
  };


  handleCategoryChange = (category) => {
    this.setState({ selectedCategory: category });
  };

  handleVideoSelect = (video) => {
    this.setState({ selectedVideo: video });
  };

  handleBackToGallery = () => {
    this.setState({ selectedVideo: null });
  };

  handleScroll = (direction) => {
    const container = document.querySelector('.button-row');
    const scrollAmount = 150; // Adjust scroll amount as needed

    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else if (direction === 'right') {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  render() {
    const { category1Videos, category2Videos, selectedCategory, selectedVideo } = this.state;
    // const videosToDisplay = selectedCategory === 'category1' ? category1Videos : category2Videos;
    const videosToDisplay = this.state[`${selectedCategory}Videos`];

    return (
      <div>
        {selectedVideo ? (
          <VideoPlayer video={selectedVideo} onBack={this.handleBackToGallery} />
        ) : (
          <>
            <div className="bg-white md:px-8 pt-4 shadow-lg mb-px border-b border-gray-300">
              <div className="container mx-auto px-4 md:px-28 flex items-center justify-between">
                <button onClick={() => this.handleScroll('left')} className="scroll-button">
                  <ChevronLeftIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                </button>
                <div className="button-row">
                  <Option2 title={"Todo"} number={0} onClick={() => this.handleCategoryChange('category0')} category={'category0'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Tipos de Vehículos"} number={1} onClick={() => this.handleCategoryChange('category1')} category={'category1'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Vías Públicas"} number={2} onClick={() => this.handleCategoryChange('category2')} category={'category2'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Velocidad"} number={3} onClick={() => this.handleCategoryChange('category3')} category={'category3'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Maniobras"} number={4} onClick={() => this.handleCategoryChange('category4')} category={'category4'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Prioridad Paso"} number={5} onClick={() => this.handleCategoryChange('category5')} category={'category5'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Transporte Personas & Mercancías"} number={6} onClick={() => this.handleCategoryChange('category6')} category={'category6'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Alumbrado, Señalización Óptica"} number={7} onClick={() => this.handleCategoryChange('category7')} category={'category7'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Señales de Circulación"} number={8} onClick={() => this.handleCategoryChange('category8')} category={'category8'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Permiso Conducir"} number={9} onClick={() => this.handleCategoryChange('category9')} category={'category9'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Estado Físico del Conductor"} number={10} onClick={() => this.handleCategoryChange('category10')} category={'category10'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Los Demás Usuarios"} number={11} onClick={() => this.handleCategoryChange('category11')} category={'category11'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Visibilidad y Peligros Vía"} number={12} onClick={() => this.handleCategoryChange('category12')} category={'category12'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Adherencia, Neumáticos y Frenos"} number={13} onClick={() => this.handleCategoryChange('category13')} category={'category13'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Seguridad y Conducción Económica"} number={14} onClick={() => this.handleCategoryChange('category14')} category={'category14'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Accidentes y Primeros Auxilios"} number={15} onClick={() => this.handleCategoryChange('category15')} category={'category15'} currentOption={selectedCategory} color="yellow" />
                  <Option2 title={"Mantenimiento del Automóvil"} number={16} onClick={() => this.handleCategoryChange('category16')} category={'category16'} currentOption={selectedCategory} color="yellow" />
                </div>
                <button onClick={() => this.handleScroll('right')} className="scroll-button">
                  <ChevronRightIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                </button>
              </div>
            </div>
            <VideoList videos={videosToDisplay} onVideoSelect={this.handleVideoSelect} />
          </>
        )}
      </div>
    );
  }
}

export function Option({ title, category, Icon, onClick, currentOption, color }) {
  let active = currentOption === category;
  return (
    <div className={`lg:py-2 lg:px-6 py-3 px-6 flex transition text-${active ? `${color ? color : "green"}-800` : "gray-500"} font-medium border-b -mb-px rounded-t-lg border-${active ? `${color ? color : "green"}-500` : "gray-400"} bg-${active ? `${color ? color : "green"}-100` : "white"} hover:bg-${active ? `${color ? color : "green"}-200` : "gray-100"} cursor-pointer `} onClick={() => onClick(title)}>
      <div className={`md:mr-1  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${active ? `${color ? color : "green"}` : "gray"}-300 text-${active ? `${color ? color : "green"}` : "gray"}-600`}>
        <Icon className={`h-4 w-4 text-${active ? `${color ? color : "green"}` : "gray"}-600`} aria-hidden="true" />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export function Option2({ title, category, number, onClick, currentOption, color }) {
  let active = currentOption === category;
  return (
    <div className={`lg:py-2 lg:px-6 py-3 px-6 flex transition text-${active ? `${color ? color : "green"}-800` : "gray-500"} font-medium border-b -mb-px rounded-t-lg border-${active ? `${color ? color : "green"}-500` : "gray-400"} bg-${active ? `${color ? color : "green"}-100` : "white"} hover:bg-${active ? `${color ? color : "green"}-200` : "gray-100"} cursor-pointer `} onClick={() => onClick(title)}>
      <div className={`md:mr-1  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${active ? `${color ? color : "green"}` : "gray"}-300 text-${active ? `${color ? color : "green"}` : "gray"}-600`}>
        {number}
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default VideoGallery;
