import Loader from './Loader'
// import Typist from 'react-typist';
import {
	CheckIcon,
} from '@heroicons/react/solid'
import {
	DuplicateIcon, ExclamationCircleIcon, ClipboardListIcon, InformationCircleIcon
} from '@heroicons/react/outline'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'

// import TypeWriter from 'react-typewriter';
import AIWriter from "react-aiwriter";
import Body, { Grid, Col } from './Body';
import axios from 'axios';
import React, { useState, useEffect } from 'react';


const API_BASE_URL = 'https://www.ricocorp.xyz';

export const OutputTextEditor = inject('store')(observer(({ store, title, desc, Icon, output, code, language, outputs, loading, children, fromColor, toColor, outputsColor, OutputsIcon }) => {
	const [output2, setOutput2] = useState("");
	const [loadingRephrase, setLoadingRephrase] = useState(false);
	const [loadingTrim, setLoadingTrim] = useState(false);
	const [loadingKeywords, setLoadingKeywords] = useState(false);

	const userId = "0";
	const userEmail = "globaladmin@ricocorp.xyz";

	const systemPromptTrim = `
Smart Trim Task:
Condense the provided text to a shorter version while maintaining all critical facts and core meanings. Ensure the trimmed version is concise, clear, and retains the essential elements of the original text.
`;

	const systemPromptRephrase = `Your primary task is to rephrase sentences or paragraphs to improve readability or adjust the style of writing while retaining the original intent, meaning, and significant details. Follow these specific instructions:
	
1. Rephrase for clarity and style: Rewrite sentences or paragraphs using clear, straightforward language. Ensure that the rephrased version maintains the same factual content and meaning as the original.

2. Maintain factual accuracy: Strictly adhere to the information presented in the source material without introducing interpretations or opinions.

3. Avoid plagiarism: Ensure that your outputs are sufficiently transformed from the original text but still reflect the original points accurately.

4. Ensure quality control: Review your output for coherence, fluency, and alignment with the source’s tone where appropriate.

Apply these guidelines consistently to produce reliable and useful outputs for users seeking rephrased content that preserves the accuracy and intent of the original text.
`;

	const systemPromptKeywords = `
Keyword Extraction Task:
Identify and extract the most relevant keywords from the provided text, focusing on terms that capture the core subjects and important themes.
`;


	useEffect(() => {
		setOutput2(""); // This will clear output2 whenever the output changes
	}, [output]); // Dependency array, useEffect will run again only if output changes


	async function rephraseText({ text }) {
		setLoadingRephrase(true);
		try {
			const apiUrl = `${API_BASE_URL}/users/${userId}/chats/${userId}-11/question`;

			const question = `Rephrase the following text for improved clarity and style, while maintaining the original meaning and factual accuracy: "${text}"`;

			const payload = {
				question: question,
				organization_name: "RicoCorp",
				user_name: userEmail,
				system_prompt: systemPromptRephrase,
				application_name: "VatesCognita",
			};

			const headers = {
				"Content-Type": "application/json",
				"X-Api-Key": "xxx",
			};

			const response = await axios.post(apiUrl, payload, { headers });

			if (response.status === 200 && response.data) {
				setOutput2(response.data.Answer)

			} else {
				console.error("Failed to rephrase text: ", response.status);
				setOutput2("Failed to rephrase text.");
			}

		} catch (error) {
			console.error("Error during rephrase operation: ", error);
			setOutput2("Error during rephrase operation.");
		}
		setLoadingRephrase(false);
	}

	async function trimText(text) {
		setLoadingTrim(true);
		if (!text) return; // Check if there is text to trim

		const apiUrl = `${API_BASE_URL}/users/${userId}/chats/${userId}-11/question`;

		const payload = {
			question: `Smart trim the following text while preserving critical information and original intent: "${text}"`,
			organization_name: "RicoCorp",
			user_name: "globaladmin@ricocorp.xyz",
			system_prompt: systemPromptTrim,
			application_name: "VatesCognita",
		};

		const headers = {
			"Content-Type": "application/json",
			"X-Api-Key": "xxx", // Replace with your actual API key
		};

		try {
			const response = await axios.post(apiUrl, payload, { headers });
			if (response.status === 200 && response.data) {
				setOutput2(response.data.Answer); // Update the state with the AI-trimmed text
			} else {
				console.error("Failed to smart trim text: ", response.status);
				setOutput2("Failed to smart trim text.");
			}
		} catch (error) {
			console.error("Error during smart trim operation: ", error);
			setOutput2("Error during smart trim operation.");
		}
		setLoadingTrim(false);
	}

	async function extractKeywords(text) {
		setLoadingKeywords(true);
		if (!text) return; // Ensure there is text to process

		const apiUrl = `${API_BASE_URL}/users/${userId}/chats/${userId}-11/question`;

		const payload = {
			question: `Extract keywords from the following text: "${text}"`,
			organization_name: "RicoCorp",
			user_name: "globaladmin@ricocorp.xyz",
			system_prompt: systemPromptKeywords,
			application_name: "VatesCognita",
		};

		const headers = {
			"Content-Type": "application/json",
			"X-Api-Key": "xxx", // Ensure you replace this with your actual API key
		};

		try {
			const response = await axios.post(apiUrl, payload, { headers });
			if (response.status === 200 && response.data) {
				setOutput2(response.data.Answer); // Assuming response.data.Answer contains the keywords
			} else {
				console.error("Failed to extract keywords: ", response.status);
				setOutput2("Failed to extract keywords.");
			}
		} catch (error) {
			console.error("Error during keyword extraction operation: ", error);
			setOutput2("Error during keyword extraction operation.");
		}
		setLoadingKeywords(false);
	}



	return (
		<div className="relative mb-12">
			<div className={`absolute inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"} to-${toColor ? toColor : "blue-500"} shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0 z-[-1]`}></div>
			<div className=" align-bottom bg-white md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">

				<div className=" px-6 py-6">
					<div className="sm:flex sm:items-start">
						{loading ? <>
							<Loader active={loading} className="w-10 h-10" />
						</> : <>
							<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${output ? "green" : "gray"}-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"} to-${toColor ? toColor : "blue-500"}`}>
								{Icon ? <Icon className={`h-6 w-6 text-white`} aria-hidden="true" /> : null}
							</div>
						</>}

						<div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
							<div as="h3" className="text-lg leading-6 font-medium text-gray-900">
								{title}
							</div>
							<p className="text-sm text-gray-500">
								{desc}
							</p>
						</div>

					</div>
					<div className="grid grid-cols-6 xl:grid-cols-12 xl:gap-4">
						<Col span="4">
							<button
								className="mb-1 mt-4 bg-blue-100 hover:bg-yellow-500 text-blue-600 font-bold py-1 px-2 rounded-full w-full"
								onClick={() => rephraseText({ text: output })}
								disabled={loadingRephrase}
								>
								{loadingRephrase ? "Processing..." : "Rephrase"}
							</button>
						</Col>
						<Col span="4">
							<button
								className="mb-1 mt-4  bg-blue-100 hover:bg-yellow-500 text-blue-600 font-bold py-1 px-2 rounded-full w-full"
								onClick={() => trimText(output)}
								disabled={loadingTrim}
								>
									{loadingTrim ? "Processing..." : "Trim"}
								</button>
						</Col>
						<Col span="4">
							<button
								className="mb-1 mt-4 bg-blue-100 hover:bg-yellow-500 text-blue-600 font-bold py-1 px-2 rounded-full w-full"
								onClick={() => extractKeywords(output)}
								disabled={loadingKeywords}
								>
									{loadingKeywords ? "Processing..." : "Keywords"}
								</button>
						</Col>
					</div>
					<Outputs output1={output} output2={output2} OutputsIcon={false} />

					<QuickTools output={output2} />
				</div>
			</div>
		</div>)
}))



export const QuickTools = inject('store')(observer(({ store, output }) => {
	return (
		<>
			{output ? (
				<div className="flex">
					<Shortcut
						className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
						onClick={() => store.copyToClipboard(output)}
					>
						<DuplicateIcon className="w-5 h-5" />
						<Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
							<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
								Copy text to clipboard
							</span>
						</Tooltip>
					</Shortcut>
					<div className="flex-1"></div>
					<Shortcut
						className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-gray-300"
						onClick={() => store.reportToFeedback(output)}
					>
						<ExclamationCircleIcon className="w-5 h-5" />
						<Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
							<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
								Report issue with output
							</span>
						</Tooltip>
					</Shortcut>
				</div>
			) : null}
		</>
	);
}));


const Tooltip = styled.div`
	display:none;
	white-space: nowrap;
`

const Shortcut = styled.div`
	&:hover ${Tooltip} {
		display: flex;
	}
`



function Outputs({ output1, output2, OutputsIcon }) {

	return (
		<div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-lg divide-y">
			{/* Handling output1 */}
			<div className="py-2 flex items-start">
				<div className={`mr-4 font-bold flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-7 rounded-full bg-blue-100 text-blue-600`}>
					{OutputsIcon === false ? `S` :
						OutputsIcon ? <OutputsIcon className="h-4 w-4 text-green-600" aria-hidden="true" /> :
							<CheckIcon className="h-4 w-4 text-green-600" aria-hidden="true" />}
				</div>
				<div className="output-container text-sm ">
					{output1 && <div dangerouslySetInnerHTML={{ __html: output1 }} />}
				</div>
			</div>
			{/* Handling output2 within AIWriter (if it's necessary to wrap with AIWriter) */}
			<AIWriter>
				<div className="py-2 flex items-start">
					<div className={`mr-4 font-bold flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-yellow-200 text-yellow-600`}>
						{OutputsIcon === false ? `AI` :
							OutputsIcon ? <OutputsIcon className="h-4 w-4 text-green-600" aria-hidden="true" /> :
								<CheckIcon className="h-4 w-4 text-green-600" aria-hidden="true" />}
					</div>
					<div className="output-container text-sm">
						{output2 && <div>{output2}</div>}
					</div>
				</div>
			</AIWriter>
		</div>
	)
}




export default OutputTextEditor