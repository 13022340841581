import { makeAutoObservable } from "mobx";
import axiosAPI from "./axiosAPI";

class ApiStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getQuestionIdsForVideo(videoId) {
    try {
      const response = await axiosAPI.get(`/video/${videoId}/question_ids`);
      return response.data;
    } catch (error) {
      console.error("Error fetching question IDs for video:", error);
      throw error;
    }
  }

  async getQuestionDetails(questionId) {
    try {
      const response = await axiosAPI.get(`/question/${questionId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching question details:", error);
      throw error;
    }
  }

  async getNextQuestionIds(externalUserId, licenseType, organizationName, level) {
    try {
      const response = await axiosAPI.get(
        `/user/${externalUserId}/license/${licenseType}/next_question_ids`,
        {
          params: {
            organization_name: organizationName,
            level: level,
          },
        }
      );
      return response.data.question_ids;
    } catch (error) {
      console.error("Error fetching next question IDs:", error);
      throw error;
    }
  }

  async getNextQuestion(externalUserId, licenseType, organizationName, level) {
    try {
      const response = await axiosAPI.get(
        `/user/${externalUserId}/license/${licenseType}/next_question`,
        {
          params: {
            organization_name: organizationName,
            level: level,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching next question:", error);
      throw error;
    }
  }


  async evaluateLevel(externalUserId, licenseType, organizationName) {
    try {
      const response = await axiosAPI.get(
        `/user/${externalUserId}/license/${licenseType}/level`,
        {
          params: {
            organization_name: organizationName,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error retrieving current level:", error);
      throw error;
    }
  }

  async getUserTopicPerformance(externalUserId, organizationName) {
    try {
      const response = await axiosAPI.get(
        `/user/${externalUserId}/topic-performance`,
        {
          params: {
            organization_name: organizationName,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user topic performance:", error);
      throw error;
    }
  }

}

export default new ApiStore();