import axios from 'axios';
import config from '../config';


const axiosAPI = axios.create({
  baseURL: config.llmApiBaseUrl
});

// TODO: remove!!!
console.log(process.env)

axiosAPI.interceptors.request.use((config) => {
  config.headers['X-Api-Key'] = process.env.LLM_API_KEY;
  config.headers['content-type'] = 'application/json';
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosAPI.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});

export default axiosAPI;
