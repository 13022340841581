import React, { Component } from 'react';
import noImage from '../tools/auto/no-image.jpeg';
import axios from 'axios';
import { inject } from 'mobx-react'
import PracticeSetup from './auto/PracticeSetup';
import confetti from 'canvas-confetti';
import { GridLoader } from 'react-spinners';
import VideoExplanations from './auto/InfinitePractice/VideoExplanation';
import DottedStepper from './auto/DottedStepper';
import ApiStore from '../api/ApiStore';


const API_BASE_URL = 'https://www.ricocorp.xyz';

@inject('store')
class InfinitePractice extends Component {
    constructor(props) {
        super(props);
        // Default values if props are not provided
        // const pomodoroTime = props.pomodoroTime || 25; // Default 25 minutes
        // const restingTime = props.restingTime || 5; // Default 5 minutes
        const licenseType = props.licenseType || 'permiso_b'; // Default license type


        this.state = {
            isLoading: true,
            // Initial timer state
            timer: 0, // Timer in seconds
            questionData: null,
            selectedAnswer: null, // To store the user's selected answer
            showResult: false, // To control the display of the answer feedback
            // chaptersData: [ // Mock chapters data
            //     { name: "T1", accuracy: 75 },
            //     { name: "T2", accuracy: 55 },
            //     { name: "T3", accuracy: 85 },
            //     { name: "T4", accuracy: 85 },
            //     { name: "T5", accuracy: 85 },
            //     { name: "T6", accuracy: 85 },
            //     { name: "T7", accuracy: 85 },
            //     { name: "T8", accuracy: 40 },
            //     { name: "T9", accuracy: 85 },
            //     { name: "T10", accuracy: 85 },
            //     { name: "T11", accuracy: 85 },
            //     { name: "T12", accuracy: 85 },
            //     { name: "T13", accuracy: 90.1 },
            //     { name: "T14", accuracy: 85 },
            //     { name: "T15", accuracy: 85 },
            //     { name: "T16", accuracy: 85 },
            //     { name: "T17", accuracy: 85 },
            //     // Add more chapters as needed
            // ],
            chaptersData: [],
            overallPerformance: 0,
            answeringEnabled: true,
            pomodoroTimer: 25 * 60, // Convert minutes to seconds
            restingTimer: 5 * 60,
            pomodoroRunning: true,
            restingRunning: false,
            licenseType,
            setupComplete: false,
            questionIds: props.questionIds || [],
            currentQuestionIndex: 0,
            streak: 0,
            showSnackbar: false,
            snackbarMessage: '',
            isChapterGridExpanded: false,
            currentLevel: 1,
            completionPercentage: 0,
            showChapterGrid: props.showChapterGrid !== undefined ? props.showChapterGrid : true,
        };
        this.bellSound = new Audio('/bell.mp3');
        this.bellSound.load();
        this.bellSound.onerror = (e) => console.error("Bell sound error:", e);

        this.successSoundPaths = [
            '/sounds/success1.mp3',
            '/sounds/success2.mp3',
            '/sounds/success3.mp3',
            '/sounds/success4.mp3',
            '/sounds/success5.mp3',
            '/sounds/success6.mp3',
            '/sounds/success7.mp3',
            '/sounds/success8.mp3',
            '/sounds/success9.mp3',
            '/sounds/success10.mp3',
            '/sounds/success11.mp3',
            '/sounds/success12.mp3',
            '/sounds/success13.mp3',
            '/sounds/success14.mp3',
            '/sounds/success15.mp3',
            '/sounds/success16.mp3',
            '/sounds/success17.mp3',
            '/sounds/success18.mp3',
        ];

        this.successSounds = [];
        this.initializeSounds();

        this.successMessages = [
            "¡Nivel desbloqueado! 🚀 Has alcanzado nuevas alturas. ¡Tómate un momento para celebrar antes de seguir conquistando!",
            "¡Bravo! Eres un auténtico maestro del aprendizaje. 🦸‍♂️ Tu esfuerzo ha dado sus frutos. ¡Sigue así y conquista el próximo nivel!",
            "¿Escuchas eso? 🎶 Es el sonido de la victoria y de un nuevo nivel. ¡Que siga la fiesta!",
            "¡Uh, uh! Nivel desbloqueado como un ninja! 🥷 Nadie ha visto cómo lo hiciste... pero sabemos que lo hiciste genial.",
            "¡Mira tú! Eres como un Pokémon en evolución. 🐉 Has desbloqueado un nuevo nivel.",
            "¡Estás a un paso de la cima! ⛰️ Un nivel más ha caído ante tu sabiduría. ¿Listo para ascender más alto?",
            "¡Sí, lo hiciste! 🎯 Has superado otro desafío. ¡El próximo nivel te está esperando con los brazos abiertos!",
        ];
    }

    initializeSounds() {
        this.successSoundPaths.forEach(path => {
            const sound = new Audio(path);
            sound.load();
            sound.onerror = (e) => console.error(`Error loading sound ${path}:`, e);
            this.successSounds.push(sound);
        });
    }

    shuffleQuestionIds = () => {
        const { questionIds } = this.state;
        for (let i = questionIds.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [questionIds[i], questionIds[j]] = [questionIds[j], questionIds[i]];
        }
        this.setState({ questionIds, currentQuestionIndex: 0 });
    };

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000);
        this.shuffleQuestionIds();
        this.fetchTopicPerformance();
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    fetchTopicPerformance = async () => {
        try {
            const userId = this.props.store.profile._id;
            const organizationName = this.props.store.profile.organization;
            const performance = await ApiStore.getUserTopicPerformance(userId, organizationName);

            const chaptersData = Object.entries(performance.topic_performance).map(([name, accuracy]) => ({
                name,
                accuracy
            }));

            this.setState({
                chaptersData,
                overallPerformance: performance.overall_performance
            });
        } catch (error) {
            console.error('Error fetching topic performance:', error);
        }
    };

    evaluateLevel = async (silent = false) => {
        try {
            const response = await ApiStore.evaluateLevel(
                this.props.store.profile._id,
                "permiso_b",
                this.props.store.profile.organization
            );
            const currentLevel = response.current_level;
            const completionPercentage = response.completion_percentage;

            // Check if the level has changed
            if (currentLevel > this.state.currentLevel && !silent) {
                // Trigger success effects
                this.triggerSuccessEffects();

                // Pick a random success message
                const randomMessageIndex = Math.floor(Math.random() * this.successMessages.length);
                const randomMessage = this.successMessages[randomMessageIndex];

                // Show the snackbar with the random message
                this.showSnackbar(randomMessage);
            }

            this.setState({ currentLevel, completionPercentage });
        } catch (error) {
            console.error('Error evaluating level:', error);
        }
    };

    handleSetupComplete = (options) => {
        const { licenseType, pomodoroTime, restingTime } = options;
        this.setState({
            licenseType,
            pomodoroTime,
            restingTime,
            pomodoroTimer: pomodoroTime * 60,
            restingTimer: restingTime * 60,
            setupComplete: true,
        }, () => {
            this.shuffleQuestionIds();
            this.loadNextQuestion();
            this.evaluateLevel(true);
        });
    };

    tick() {
        const { pomodoroTimer, pomodoroRunning, restingTimer, restingRunning, setupComplete } = this.state;

        if (!setupComplete) {
            return;
        }

        if (pomodoroRunning && pomodoroTimer > 0) {
            this.setState({
                pomodoroTimer: pomodoroTimer - 1,
                timer: this.state.timer + 1,  // Update the general timer only when practicing
            });

            if (pomodoroTimer - 1 === 0) {
                this.playBellSound();
                this.setState({ pomodoroRunning: false, restingRunning: true });
                // alert("Time's up! Take a break.");
            }
        }

        if (restingRunning && restingTimer > 0) {
            this.setState({
                restingTimer: restingTimer - 1,
            });

            if (restingTimer - 1 < 0) {
                this.playBellSound();
                this.setState({ restingRunning: false });
                alert("Break's over! Time to get back to practice.");
                this.resetPomodoroTimer();
            }
        }
    }

    playBellSound() {
        if (this.bellSound) {
            this.bellSound.play().catch(error => {
                console.error("Error playing bell sound:", error);
            });
        }
    }


    resetPomodoroTimer() {
        const { pomodoroTime, restingTime } = this.state;
        this.setState({
            pomodoroTimer: pomodoroTime * 60,
            pomodoroRunning: true,
            restingTimer: restingTime * 60,
        }, this.loadNextQuestion);
    }


    loadNextQuestion = async () => {
        this.setState({
            isLoading: true,
            nextQuestionAvailable: false,
            showResult: false,        // Hide results when loading next question
            selectedAnswer: null,      // Clear previously selected answer
            answeringEnabled: true
        });

        try {
            let response;
            const { questionIds, currentQuestionIndex, currentLevel } = this.state;
            const userId = this.props.store.profile._id;
            const organizationName = this.props.store.profile.organization;
            const applicationName = this.props.store.applicationName
            const apiBaseUrl = this.props.store.llmApiBaseUrl

            this.setState(this.evaluateLevel);

            if (questionIds.length > 0) {
                // If we have question IDs, use them
                const questionId = questionIds[currentQuestionIndex];
                response = await axios.get(`${apiBaseUrl}/question/${questionId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": "xxx",
                    },
                    params: {
                        organization_name: organizationName,
                        application_name: applicationName,
                    }
                });
            } else {
                // If no question IDs, use the existing method
                response = await ApiStore.getNextQuestion(userId, "permiso_b", organizationName, currentLevel);
            }

            const { question_id, text, explanation, options, correct_answer, image_data, video_ranges } = response.data;
            const answers = Object.entries(options)
                .filter(([key, value]) => value)  // This line filters out entries with empty values
                .map(([key, value]) => ({
                    text: value,
                    isCorrect: key === correct_answer
                }));

            const { hasImage, imageSrc } = image_data
                ? { hasImage: true, imageSrc: `data:image/png;base64,${image_data}` }
                : { hasImage: false, imageSrc: noImage };

            this.setState(prevState => ({
                questionData: {
                    questionId: question_id,
                    question: text,
                    image: imageSrc,
                    answers: answers,
                    explanation: explanation,
                    videoRanges: video_ranges,
                },
                isLoading: false,
                currentQuestionIndex: questionIds.length > 0 ? (prevState.currentQuestionIndex + 1) % questionIds.length : 0
            }));

        } catch (error) {
            console.error('Error loading question:', error);
            this.setState({ isLoading: false, questionData: null }, this.evaluateLevel);
        }
    };

    handleAnswerSelection = async (answer) => {
        if (!this.state.answeringEnabled) return;

        const userId = this.props.store.profile._id;
        const { questionData } = this.state;
        const organizationName = this.props.store.profile.organization;
        this.setState({ selectedAnswer: answer, showResult: true, nextQuestionAvailable: true, answeringEnabled: false });

        const payload = {
            questionId: questionData.questionId,
            isCorrect: answer.isCorrect
        };

        try {
            await axios.post(`${API_BASE_URL}/user/${userId}/answer_question`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "xxx",
                },
                params: {
                    organization_name: organizationName
                }
            });
        } catch (error) {
            console.error('Error submitting answer:', error);
        }

        if (answer.isCorrect) {
            const newStreak = this.state.streak + 1;
            this.setState({ streak: newStreak });

            if (newStreak % 3 === 0) {
                this.triggerSuccessEffects();
                this.showSnackbar(`¡Excelente! Has respondido ${newStreak} preguntas seguidas correctamente. Sigue así :)`);
            }
        } else {
            this.setState({ streak: 0 });
        }
        // Fetch updated topic performance after answering a question
        await this.fetchTopicPerformance();
    };

    showSnackbar = (message) => {
        this.setState({ showSnackbar: true, snackbarMessage: message });
        setTimeout(() => {
            this.setState({ showSnackbar: false, snackbarMessage: '' });
        }, 6000);
    };

    triggerSuccessEffects = () => {
        // Play sound
        const randomIndex = Math.floor(Math.random() * this.successSounds.length);
        const randomSound = this.successSounds[randomIndex];
        randomSound.load();
        randomSound.play().catch(error => {
            console.error("Error playing success sound:", error);
        });

        // Trigger confetti
        confetti({
            particleCount: 200,
            spread: 80,
            origin: { y: 0.95 }
        });
    };

    handleSkipRest = () => {
        this.setState({ restingRunning: false });
        this.resetPomodoroTimer();
    };

    formatTime(seconds) {
        if (seconds <= 0) return "00:00:00";
        return new Date(seconds * 1000).toISOString().substr(12, 7);
    }

    renderQuestion = () => {
        const { questionData, selectedAnswer, showResult, answeringEnabled, streak } = this.state;

        return (
            <div className="md:grid md:grid-cols-2 gap-4 items-start">
                {/* Other elements */}

                <div className="flex flex-col justify-start">
                    <div>
                        <h2 className="font-semibold text-lg mb-2">{questionData.question}</h2>
                    </div>
                    <div className="text-center mb-5">
                        <span className="font-bold text-xl">Aciertos seguidos: {streak}</span>
                    </div>
                    {questionData.answers.map((answer, index) => (
                        <button
                            key={index}
                            onClick={() => this.handleAnswerSelection(answer)}
                            disabled={!answeringEnabled}
                            className={`font-bold py-2 px-4 rounded-full mb-2 w-full text-left transition-all duration-300
                                ${showResult ?
                                    (selectedAnswer === answer ?
                                        (answer.isCorrect ? 'bg-green-500 hover:bg-green-600 text-white animate-pulse' : 'bg-red-500 hover:bg-red-600 text-white') :
                                        'bg-gray-200 hover:bg-gray-300') :
                                    'bg-gray-200 hover:bg-gray-300'}`}>
                            {answer.text}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    renderChaptersGrid = () => {
        const { chaptersData, currentLevel, overallPerformance } = this.state;
        return (
            <div
                className="relative"
                onMouseEnter={() => this.setState({ isChapterGridExpanded: true })}
                onMouseLeave={() => this.setState({ isChapterGridExpanded: false })}
            >
                <DottedStepper currentStep={currentLevel} />
                <div className="cursor-pointer text-center py-2 bg-gray-200 rounded-t-lg">
                    <span className="font-bold">
                        {overallPerformance === 0 ? '---' : `${Math.round(overallPerformance)}% aciertos`}
                    </span>
                    <span className="ml-2">{this.state.isChapterGridExpanded ? '▲' : '▼'}</span>
                </div>
                <div className={`overflow-hidden transition-all duration-300 ${this.state.isChapterGridExpanded ? 'max-h-96' : 'max-h-0'}`}>
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 p-4 bg-white rounded-b-lg">
                        {chaptersData.map((chapter, index) => (
                            <div
                                key={index}
                                className={`p-2 rounded-lg text-center transition-all duration-300
                            ${chapter.accuracy === 0 ? 'bg-gray-50 text-gray-500' :
                                        chapter.accuracy < 60 ? 'bg-red-50 text-red-700' :
                                            chapter.accuracy > 90 ? 'bg-green-50 text-green-700' :
                                                'bg-blue-50 text-gray-700'}`}
                            >
                                <div className="font-bold">{chapter.name}</div>
                                <span className="text-sm">
                                    {chapter.accuracy === 0 ? 'Sin intentos' : `${Math.round(chapter.accuracy)}%`}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { isLoading, questionData, selectedAnswer, showResult, chaptersData, timer, nextQuestionAvailable, pomodoroTimer, restingTimer, setupComplete, pomodoroRunning, restingRunning, showSnackbar, snackbarMessage, streak } = this.state;

        if (!setupComplete) {
            return <PracticeSetup style={{
                position: 'absolute',
                top: '10%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
                onSetupComplete={this.handleSetupComplete} />;
        }

        let hasImage = false;
        let imageSrc = noImage;
        if (questionData && questionData.image) {
            hasImage = questionData.image !== noImage;
            imageSrc = questionData.image;
        }

        if (isLoading) {
            const containerStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',
            };

            return (
                <div style={containerStyle}>
                    <GridLoader color="#767676" size="30px" />
                </div>
            );
        }

        if (!questionData) {
            return <div>No hay preguntas disponibles.</div>;
        }

        return (
            <div className="space-y-4 relative bg-gray-100">
                {!restingRunning && (
                    <div className="absolute inset-0 bg-black bg-opacity-5 z-0"></div>
                )}
                <div className="relative z-10">
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 p-4 mx-4 my-6 flex justify-between items-center">
                        <div className="text-xl font-bold text-gray-800">Práctica Infinita</div>
                        {!restingRunning && <div className="text-lg font-semibold text-blue-600">{this.formatTime(timer)}</div>}
                        <div className="text-lg font-semibold">
                            {pomodoroRunning ? (
                                <span className="text-green-600">Descanso en {this.formatTime(pomodoroTimer)}</span>
                            ) : (
                                <span className="text-red-600 font-bold">
                                    Tiempo de descanso: {this.formatTime(restingTimer)}
                                </span>
                            )}
                        </div>
                    </div>

                    {restingRunning ? (
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 p-6 mx-4 my-6 flex flex-col justify-center items-center">
                            <div className="text-4xl font-bold text-gray-800 mb-4">
                                Tiempo de Descanso
                            </div>
                            <div className="text-7xl font-bold text-green-500 mb-4">
                                {this.formatTime(restingTimer)}
                            </div>
                            <button
                                onClick={this.handleSkipRest}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300"
                            >
                                Continuar
                            </button>
                        </div>
                    ) : (
                        <div className="md:grid md:grid-cols-3 gap-6">
                            <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-300 p-6 m-4 md:col-span-1 flex justify-center items-center">
                                <div style={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
                                    <div style={{
                                        width: hasImage ? '100%' : '50%',
                                        paddingBottom: hasImage ? '100%' : '50%',
                                        opacity: hasImage ? 1 : 0.2,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <img
                                            src={imageSrc}
                                            alt={hasImage ? "Question illustration" : "No image available"}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: hasImage ? 'contain' : 'cover'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-300 p-6 m-4 md:col-span-2 flex flex-col ring-2 ring-blue-200">
                                <div className="text-right mb-4">
                                    <span className="italic text-m text-blue-600">Aciertos seguidos: {streak}</span>
                                </div>
                                <h2 className="font-semibold text-xl mb-4 text-gray-800">{questionData.question}</h2>
                                {questionData.answers.map((answer, index) => (
                                    <button
                                        key={index}
                                        onClick={() => this.handleAnswerSelection(answer)}
                                        className={`py-3 px-5 rounded-full mb-3 w-full text-left transition-all duration-300 text-lg
                                            ${showResult
                                                ? (selectedAnswer === answer
                                                    ? (answer.isCorrect
                                                        ? 'bg-green-500 hover:bg-green-600 text-white animate-pulse shadow-lg'
                                                        : 'bg-red-500 hover:bg-red-600 text-white shadow-lg')
                                                    : 'bg-white hover:bg-gray-100 text-black')
                                                : 'bg-white hover:bg-gray-100 text-black border border-gray-300 shadow'}`}>
                                        {answer.text}
                                    </button>
                                ))}
                                {showResult && (
                                    <div className="mt-4">
                                        <p className={`font-bold ${selectedAnswer.isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                                            {selectedAnswer.isCorrect ? "Correcto!" : "Incorrecto!"}
                                        </p>
                                        {(questionData.explanation || (questionData.videoRanges && questionData.videoRanges.length > 0)) && (
                                            <>
                                                <h3 className="font-bold text-lg mb-2 mt-10">Explicación:</h3>
                                                {questionData.explanation && (
                                                    <p className="mb-4">{questionData.explanation}</p>
                                                )}
                                                {questionData.videoRanges && questionData.videoRanges.length > 0 && (
                                                    <VideoExplanations videoRanges={questionData.videoRanges} />
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                                {nextQuestionAvailable && (
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300"
                                        onClick={this.loadNextQuestion}>
                                        Siguiente pregunta
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {this.state.showChapterGrid && (
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 p-6 mx-4 my-6">
                            {this.renderChaptersGrid()}
                        </div>
                    )}

                    {showSnackbar && (
                        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-6 py-3 rounded-full shadow-lg z-50 text-lg font-semibold animate-bounce">
                            {snackbarMessage}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default InfinitePractice;