// import jobad from './jobad';
// import summarize from './summarize';
// import codeInterpret from './interpret';
// import intro from './intro';
// import helloworld from './helloworld';
// import example from './example';
import cytogenetics from './cyto/cytogenetics_assistant';
import iscnAssistant from './cyto/iscn_assistant';
// import permisob from './permisob';
import permisob_rag from './auto/permisob_rag';
import permisob_add from './permisob_creacion_preguntas';
import vedMedKaryotyping from './cyto/vedmed_karyotyping';
import virtusGPT from './virtus/virtusGPT';
// import angelmanSyndrome from './cyto/angelman_syndrome';


// NOTE: Tools outside this list may scape permissions
const TOOLS = [
	// codeInterpret,
	// intro,
	// jobad,
	// summarize,
	// helloworld,
	// example,
	vedMedKaryotyping,
	// cytogenetics,
	// iscnAssistant,
	// angelmanSyndrome,
	permisob_rag,
	permisob_add,
	virtusGPT,
]

export default TOOLS
