import VedMedKaryotyping from '../../Components/cyto/VedMedKaryotyping';
import {
	ViewListIcon,
} from '@heroicons/react/solid'


const vedMedKaryotyping = {
    title: "Karyotyping Assistant", // Display name of the tool
    category: "Kayra",
    desc: "Designed to accelerate and enhance the accuracy of chromosomal analysis for genetic research and diagnostics.",
	to: "/cyto/karyotyping",
    Icon: ViewListIcon, // Define your icon component or import it
    permissions: ['cyto'],
    fromColor: "green-500",
    toColor: "blue-700",
    component: VedMedKaryotyping, // The React component that implements the tool
  };
  
  export default vedMedKaryotyping;
  