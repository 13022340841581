import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Body, { Grid, Col } from '../Body';
import noImage from '../../tools/auto/no-image.jpeg';
import { DocumentAddIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { inject, observer } from 'mobx-react';

const API_BASE_URL = 'https://www.ricocorp.xyz';

const QuestionAugmentation = inject('store')(observer(({ store }) => {
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [editableQuestion, setEditableQuestion] = useState({});
    const [loading, setLoading] = useState(false); // State for loading indication

    const organizationName = store.profile.organization;
    const externalUserId = store.profile._id;

    // Fetch questions from the API
    useEffect(() => {
        const fetchQuestions = async () => {
            const url = `${API_BASE_URL}/questions?organization_name=${encodeURIComponent(organizationName)}`;
            try {
                const response = await axios.get(url);
                setQuestions(response.data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [organizationName]);

    const handleSelectQuestion = (question) => {
        setSelectedQuestion(question);
        setEditableQuestion({ ...question });  // Make a copy of the question for editing
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditableQuestion(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const hasChanges = () => {
        return Object.keys(selectedQuestion).some(
            key => editableQuestion[key] !== selectedQuestion[key]
        );
    };

    const handleSaveAsNew = async () => {
        setLoading(true);  // Start loading
    
        const postData = {
            organization_name: organizationName,
            external_user_id: externalUserId,
            original_question_id: editableQuestion.QuestionID,
            question: editableQuestion.Question,
            answerA: editableQuestion.AnswerA,
            answerB: editableQuestion.AnswerB,
            answerC: editableQuestion.AnswerC,
            answerD: editableQuestion.AnswerD,
            correct_answer: editableQuestion.CorrectAnswer,
            explanation: editableQuestion.Explanation,
            topics: editableQuestion.Topics,
            tags: editableQuestion.Tags,
            image_path: null,
            image_data: selectedQuestion.ImageData ? selectedQuestion.ImageData : null
        };
    
        try {
            const response = await axios.post(`${API_BASE_URL}/add_augmented_question`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 201) {
                alert('Nueva pregunta guardada con éxito!');
                setEditableQuestion({ ...selectedQuestion });
            } else {
                throw new Error('Failed to save the question due to server error.');
            }
        } catch (error) {
            console.error('Error saving new question:', error);
            alert('Error al guardar la nueva pregunta.');
        } finally {
            setLoading(false);  // End loading
        }
    };
    
    const handleAugmentQuestion = async () => {
        if (!editableQuestion) {
            alert('No hay pregunta seleccionada para aumentar.');
            return;
        }

        setLoading(true); // Start loading
        // Construct the data object in the format the backend expects
        const questionData = {
            questionID: selectedQuestion.QuestionID,
            question: selectedQuestion.Question,
            answers: {
                A: selectedQuestion.AnswerA || "",
                B: selectedQuestion.AnswerB || "",
                C: selectedQuestion.AnswerC || "",
                D: selectedQuestion.AnswerD || ""
            },
            correctAnswer: selectedQuestion.CorrectAnswer,
            explanation: selectedQuestion.Explanation,
            topics: selectedQuestion.Topics || "", // Assuming you have 'Topics' field; if not, adjust accordingly
            tags: selectedQuestion.Tags ? selectedQuestion.Tags.split(', ') : [],
            // imageData: editableQuestion.ImageData || null
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/augment_question`, questionData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const updatedQuestion = response.data;
            setEditableQuestion({
                ...editableQuestion,
                Question: updatedQuestion.question,
                AnswerA: updatedQuestion.answers.A,
                AnswerB: updatedQuestion.answers.B,
                AnswerC: updatedQuestion.answers.C,
                AnswerD: updatedQuestion.answers.D,
                CorrectAnswer: updatedQuestion.correctAnswer,
                Explanation: updatedQuestion.explanation,
                Tags: updatedQuestion.tags.join(', '), // Convert array back to string if your component expects a string
                // ImageData: updatedQuestion.imageData
            });
            alert('Pregunta aumentada con éxito!');
        } catch (error) {
            console.error('Error augmenting question:', error);
            alert('Error al aumentar la pregunta.');
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleDeleteQuestion = async (questionId) => {
        if (!window.confirm("¿Estás seguro de que deseas eliminar esta pregunta? Si existen, también se eliminarán todos los registros de respuestas de usuarios asociados a esta pregunta, asi como preguntas aumentadas con IA basadas en ésta pregunta.")) {
            return;
        }

        setLoading(true);  // Start loading

        try {
            const response = await axios.delete(`${API_BASE_URL}/delete_question`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    question_id: questionId
                }
            });

            if (response.status === 200) {
                alert('Pregunta eliminada con éxito!');
                setQuestions(prevQuestions => prevQuestions.filter(q => q.QuestionID !== questionId));
                setSelectedQuestion(null);
                setEditableQuestion({});
            } else {
                throw new Error('Failed to delete the question due to server error.');
            }
        } catch (error) {
            console.error('Error deleting question:', error);
            alert('Error al eliminar la pregunta.');
        } finally {
            setLoading(false);  // End loading
        }
    };

    return (
        <div>
            <Grid>
                <Col span="6">
                    {selectedQuestion ? (
                        <div className="relative mb-12">
                            <div className={`absolute inset-0 bg-gradient-to-r from-gray-500 to-gray-500 shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`} style={{ zIndex: -1 }}></div>
                            <div className="flex-1 bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-4">
                                <div className="flex mb-4 w-full">
                                    <button
                                        // onClick={this.handleSubmit}
                                        // disabled={loading}
                                        className="bg-gray-500 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-full">
                                        Pregunta {selectedQuestion.QuestionID}
                                    </button>
                                </div>
                                <div className="space-y-4 mb-6">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={selectedQuestion.ImageData ? `data:image/jpeg;base64,${selectedQuestion.ImageData}` : noImage} alt="Question Image" className="max-w-full h-auto" />
                                    </div>
                                    <p><strong>Pregunta:</strong> {selectedQuestion.Question}</p>
                                    <p><strong></strong> {selectedQuestion.AnswerA}</p>
                                    <p><strong></strong> {selectedQuestion.AnswerB}</p>
                                    <p><strong></strong> {selectedQuestion.AnswerC || ''}</p>
                                    <p><strong></strong> {selectedQuestion.AnswerD || ''}</p>
                                    <p><strong>Respuesta:</strong> {selectedQuestion.CorrectAnswer}</p>
                                    <p><strong>Explicación:</strong> {selectedQuestion.Explanation}</p>
                                    <p><strong>Tags:</strong> {selectedQuestion.Tags}</p>
                                </div>
                                {/* <div className="flex w-full">
                                    <button
                                        // onClick={this.handleSubmit}
                                        // disabled={loading}
                                        className="bg-gray-500 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-full">
                                        Pregunta {selectedQuestion.QuestionID}
                                    </button>
                                </div> */}
                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handleDeleteQuestion(selectedQuestion.QuestionID)}
                                        disabled={loading}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                                        <TrashIcon className="h-5 w-5 mr-2" />
                                        Eliminar pregunta
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="relative mb-12"><h1><strong>Por favor seleccione una pregunta de la lista.</strong></h1></div>
                    )}
                </Col>
                <Col span="6">
                    {selectedQuestion ? (
                        <div className="relative mb-12">
                            <div className={`absolute inset-0 bg-gradient-to-r from-blue-500 to-green-500 shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`} style={{ zIndex: -1 }}></div>
                            <div className="flex-1 bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-4">
                                <div className="flex mb-4 w-full">
                                    <button
                                        onClick={handleAugmentQuestion}
                                        disabled={loading} // Disable the button while loading
                                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center w-full justify-center ${loading ? 'bg-gray-400 hover:bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'}`}
                                    >
                                        {loading ? (
                                            <>
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.5 8.5l2.5-3.209z"></path>
                                                </svg>
                                                Procesando...
                                            </>
                                        ) : (
                                            <>
                                                <DocumentAddIcon className="h-5 w-5 mr-2" />
                                                Aumentar con IA
                                            </>
                                        )}
                                    </button>
                                </div>
                                <div className="space-y-4 mb-6">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={editableQuestion.ImageData ? `data:image/jpeg;base64,${editableQuestion.ImageData}` : noImage} alt="Editable Question Image" className="max-w-full h-auto" />
                                    </div>
                                    <p><strong>Pregunta:</strong></p>
                                    <textarea
                                        name="Question"
                                        value={editableQuestion.Question}
                                        onChange={handleChange}
                                        className="w-full p-8 border rounded"
                                        rows="3"
                                    />
                                    <p><strong>Respuesta A</strong></p>
                                    <input
                                        type="text"
                                        name="AnswerA"
                                        value={editableQuestion.AnswerA}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    />
                                    <p><strong>Respuesta B</strong></p>
                                    <input
                                        type="text"
                                        name="AnswerB"
                                        value={editableQuestion.AnswerB}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    />
                                    <p><strong>Respuesta C</strong></p>
                                    <input
                                        type="text"
                                        name="AnswerC"
                                        value={editableQuestion.AnswerC || ''}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    />
                                    <p><strong>Respuesta D</strong></p>
                                    <input
                                        type="text"
                                        name="AnswerD"
                                        value={editableQuestion.AnswerD || ''}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    />
                                    <p><strong>Respuesta Correcta</strong></p>
                                    <select
                                        name="CorrectAnswer"
                                        value={editableQuestion.CorrectAnswer || ''}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                    </select>
                                    <p><strong>Explicación</strong></p>
                                    <textarea
                                        name="Explanation"
                                        value={editableQuestion.Explanation}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                        rows="10"
                                    />
                                    <p><strong>Tags:</strong></p>
                                    <input
                                        type="text"
                                        name="Tags"
                                        value={editableQuestion.Tags || ''}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="flex space-x-12 w-full">
                                    <button
                                        onClick={handleSaveAsNew}
                                        disabled={!hasChanges() || loading}
                                        className={`text-white font-bold py-2 px-4 rounded inline-flex items-center w-full justify-center ${
                                            (!hasChanges() || loading) ? 'bg-gray-400 hover:bg-gray-400' : 'bg-green-500 hover:bg-green-700'
                                        }`}
                                    >
                                        <PlusIcon className="h-5 w-5 mr-2" />
                                        Guardar como nueva pregunta
                                    </button>
                                </div>
                            </div>
                        </div>

                    ) : (
                        <p></p>
                    )}
                </Col>
            </Grid>
            <div className="flex-1 min-w-[40%] mb-16">
                <table className="min-w-full max-w-4xl divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xm font-medium text-gray-500 uppercase tracking-wider">
                                Num.
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xm font-medium text-gray-500 uppercase tracking-wider">
                                PREGUNTA
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xm font-medium text-gray-500 uppercase tracking-wider">
                                ACCIONES
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {questions.map((question, idx) => (
                            <tr key={idx} onClick={() => handleSelectQuestion(question)} className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {question.QuestionID}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {question.Question}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <button
                                        onClick={() => handleDeleteQuestion(question.QuestionID)}
                                        disabled={loading}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                    >
                                        <TrashIcon className="h-5 w-5 mr-2" />
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}));

export default QuestionAugmentation;
