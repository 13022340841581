import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { computed, } from 'mobx'
import MainBody from './Components/Body'
import { Helmet } from "react-helmet";
import angelmanSyndrome from './tools/cyto/angelman_syndrome.js';
import researchPaper from './tools/cyto/research_paper';
import infiniteP from './tools/auto/infinite_practice.js';
import addQuestion from './tools/auto/admin_add_question.js'



import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Body extends Component {

	@computed get permissions() {
		return this.props.store.tools.filter(tool =>
			tool.permissions.some(r => this.props.store.profile.permissions.includes(r))
		)
	}

	@computed get laboratory() {
		return this.permissions.filter(tool => tool.category === 'Cytogenetics' || tool.category === 'Kayra' || tool.category === 'Cyca' || tool.category === 'Scion')
	}


	@computed get autoescuela() {
		return this.permissions.filter(tool => tool.category === 'Instructor IA')
	}

	@computed get admin() {
		return this.permissions.filter(tool => tool.category === 'Administración')
	}

	@computed get virtus() {
		return this.permissions.filter(tool => tool.category === 'Virtus')
	}

	render() {
		return (

			<>
				<Helmet>
					<title>{`Vates`}</title>
				</Helmet>
				<MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">


					{this.laboratory.length ? <>
						<Title title="VedMed Tools" />
						<Grid>
							{this.laboratory.map((tool, index) =>
								<Tool
									key={index}
									group={tool.category}
									title={tool.title}
									to={tool.to}
									Icon={tool.Icon}
									desc={tool.desc}
									fromColor={tool.fromColor}
									toColor={tool.toColor}
								/>)}

							{/* <Tool
								group="Chroma"
								title="Chromosome Analysis Toolkit"
								to=""
								Icon={angelmanSyndrome.Icon}
								desc=""
								fromColor="blue-800"
								toColor={angelmanSyndrome.toColor}
							/> */}

						</Grid>
						<Divider />
						{/* <Title title="Diseases" />
						<Grid>
							<Tool
								group={angelmanSyndrome.category}
								title={angelmanSyndrome.title}
								to={angelmanSyndrome.to}
								Icon={angelmanSyndrome.Icon}
								desc={angelmanSyndrome.desc}
								fromColor={angelmanSyndrome.fromColor}
								toColor={angelmanSyndrome.toColor}
							/>
							<Tool
								group="ALL.AI"
								title={"Acute Lymphoblastic Leukemia"}
								to=""
								Icon={angelmanSyndrome.Icon}
								desc="ALL is a cancer impacting the blood and bone marrow, characterized by the excessive production of immature white blood cells, which hinders the production of healthy blood cells and leads to various health issues."
								fromColor="green-600"
								toColor={angelmanSyndrome.toColor}
							/>
							<Tool
								group="AML.AI"
								title={"Acute Myeloid Leukemia"}
								to=""
								Icon={angelmanSyndrome.Icon}
								desc="AML is a cancer impacting the blood and bone marrow, characterized by the rapid proliferation of abnormal myeloid cells. This leads to the accumulation of these cells in the bone marrow, disrupting normal blood cell production."
								fromColor="green-600"
								toColor={angelmanSyndrome.toColor}
							/>
						</Grid> */}
						{/* <Divider />
						<Title title="Academic" />
						<Grid>
							<Tool
								group={researchPaper.category}
								title={researchPaper.title}
								to={researchPaper.to}
								Icon={researchPaper.Icon}
								desc={researchPaper.desc}
								fromColor="red-300"
								toColor={researchPaper.toColor}
							/>
							<Tool
								group="Ignis.gen"
								title="Educational Platform"
								to=""
								Icon={researchPaper.Icon}
								desc="Optimize your learning with science-based techniques: pomodoro, spaced repetition and active recall for durable and efficient knowledge retention."
								fromColor="red-500"
								toColor={researchPaper.toColor}
							/>
							<Tool
								group="Virtus"
								title="Multimodel ChatBot"
								to="/edu/vgpt"
								desc="OpenAI's ChatGPT clone with access to additional models and features."
							/>
						</Grid>
						<Divider /> */}
					</> : null}

					{this.autoescuela.length ? <>
						<Title title="Permiso B" />
						<Grid>
							{/* <Tool
								group="Instructor Humano"
								title="Carolina"
								to="/auto/carolina"
								Icon={infiniteP.Icon}
								desc="Pregunta a nuestra experta en el permiso B."
								fromColor="yellow-400"
								toColor={infiniteP.toColor}
							/> */}
							{this.autoescuela.map((tool, index) =>
								<Tool
									key={index}
									group={tool.category}
									title={tool.title}
									to={tool.to}
									Icon={tool.Icon}
									desc={tool.desc}
									fromColor={tool.fromColor}
									toColor={tool.toColor}
								/>)}
							<Tool
								group="Estudio"
								title="Videos Explicativos"
								to="/auto/video-gallery"
								Icon={infiniteP.Icon}
								desc="Galeria de videos explicativos sobre cada tema del Permiso B."
								fromColor="yellow-400"
								toColor={infiniteP.toColor}
							/>
							<Tool
								group={infiniteP.category}
								title={infiniteP.title}
								to={infiniteP.to}
								Icon={infiniteP.Icon}
								desc={infiniteP.desc}
								fromColor={infiniteP.fromColor}
								toColor={infiniteP.toColor}
							/>
							{/* <Tool
								group="Estudio"
								title="Simulación Exámen"
								to="/auto/exam-simulator"
								Icon={infiniteP.Icon}
								desc="Prepárate con precisión: nuestra herramienta simula exámenes oficiales y, mediante IA, determina el momento óptimo para que afrontes tu examen real con confianza y éxito."
								fromColor="yellow-400"
								toColor="black"
							/> */}
						</Grid>
						<Divider />
					</> : null}


					{this.admin.length ? <>
						<Title title="Madel Admin" />
						<Grid>
							{/* {this.admin.map((tool, index) =>
								<Tool
									key={index}
									group={tool.category}
									title={tool.title}
									to={tool.to}
									Icon={tool.Icon}
									desc={tool.desc}
									fromColor={tool.fromColor}
									toColor={tool.toColor}
								/>)} */}
							<Tool
								key={2}
								group={addQuestion.category}
								title={addQuestion.title}
								to={addQuestion.to}
								Icon={addQuestion.Icon}
								desc={addQuestion.desc}
								fromColor={addQuestion.fromColor}
								toColor={addQuestion.toColor}
							/>
						</Grid>
						<Divider />
					</> : null}

					{this.virtus.length ? <>
						<Title title="Virtus" />
						<Grid>
							{this.admin.map((tool, index) =>
								<Tool
									key={index}
									group={tool.category}
									title={tool.title}
									to={tool.to}
									desc={tool.desc}
									fromColor={tool.fromColor}
								/>)}
								
							<Tool
								group="Virtus"
								title="Multimodel ChatBot"
								to="/edu/vgpt"
								desc="OpenAI's ChatGPT clone with access to additional models and features."
								fromColor= "blue-700"
							/>
						</Grid>
						<Divider />
					</> : null}
				</MainBody>
			</>)
	}
}

export const Divider = () => <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12"> <div></div>
	<div></div></div>

export const Title = ({ title }) => <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
	{title}
</h2>

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative ">

	<div className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}>
		<div className="p-4">
			<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
			<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
			<p className="mt-1 pr-1 text-sm ">{desc} </p>
		</div>
	</div>
</Link>



export default Body