import React, { Component } from 'react';
import RichTextEditor from 'react-rte';
import Immutable from 'immutable';
import { EditorState, DefaultDraftBlockRenderMap } from 'draft-js';

const blockRenderMap = Immutable.Map({
  'small': {
    element: 'small'
  }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

class TextEditor extends Component {

  // state = {
  //   value: RichTextEditor.createEmptyValue()
  // }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
        // this.props.onChange(value.toString('html'));
        this.props.onChange(value);
    }
  };

  // Method to handle text selection across multiple blocks
  getSelectedText = () => {
    // const editorState = this.state.value.getEditorState();
    const editorState = this.props.editorState.getEditorState();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const currentContent = editorState.getCurrentContent();
    let selectedText = '';
    let isStartBlock = true;

    currentContent.getBlockMap().forEach((block) => {
      const key = block.getKey();
      if (key < startKey || key > endKey) {
        return;
      }

      let text = block.getText();
      if (key === startKey) {
        text = text.slice(startOffset);
      }
      if (key === endKey) {
        text = text.slice(0, endOffset);
      }
      if (isStartBlock) {
        selectedText = text;
        isStartBlock = false;
      } else {
        selectedText += '\n' + text;  // Add newline for multi-block selection
      }
    });

    console.log("Selected text:", selectedText);
    if (this.props.onSelectText) {
      this.props.onSelectText(selectedText);
    }
  };

  render() {
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Small', style: 'small' },
        { label: 'Normal', style: 'unstyled' },
        // { label: 'Heading Large', style: 'header-one' },
        // { label: 'Heading Medium', style: 'header-two' },
        // { label: 'Heading Small', style: 'header-three' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };
    return (
      <div>
        <button 
        className="bg-blue-100 hover:bg-blue-300 text-blue-500 font-bold py-2 px-4 rounded w-full"
        onClick={this.getSelectedText}>(S)elect</button>
        <RichTextEditor
          // value={this.state.value}
          value={this.props.editorState}
          onChange={this.onChange}
          toolbarConfig={toolbarConfig}
          blockRenderMap={extendedBlockRenderMap}
        />
      </div>
    );
  }
}

export default TextEditor;
