import React from 'react';

const Modal = ({ show, handleClose, title, children }) => {
    if (!show) {
      return null;
    }
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" onClick={handleClose}>
        <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 max-w-4xl shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
          <div className="mt-3">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            <div className="mt-2 px-7 py-3 max-h-[70vh] overflow-y-auto">
              {children}
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={handleClose}
                className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Modal;
