// src/components/VideoList.js
import React from 'react';
import './VideoList.css';

const VideoList = ({ videos, onVideoSelect }) => {
  return (
    <div className="video-grid">
      {videos.map((video, index) => (
        <div key={index} className="video-item" onClick={() => onVideoSelect(video)}>
          <img src={video.thumbnail_url} alt={video.title} />
          {/* <h3 className="video-title">{video.title}</h3> */}
          {/* <p>{video.author_name}</p> */}
        </div>
      ))}
    </div>
  );
};

export default VideoList;
