import {
	ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Creación Preguntas B",
	desc: "Da de alta nuevas preguntas.",
	category: "Administración",
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['auto'],

	to: "/ai/auto/add_questions",
	api: "/ai/auto/add_questions",
	
	fromColor: "blue-600",
	toColor: "yellow-500",

	output: {
		title: "Pregunta",
		desc: "Preview",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Pregunta",
		desc: "Escriba la pregunta a guardar en la plataforma.",
		// n: 1,
		prompts: [
			{ 
				title: "Tema número", 
				attr: "tema",  
				value: "", 
				placeholder: "Tema 12, tema 14", 
				label: "Tema 1: Tipos de Vehículos, Tema 2: Documentación, Tema 3: Señales, Tema 4: Alumbrado, Tema 4: Utilización de la Vía...",
				// type: "textarea",
				maxLength: 40,
				// max: 100,
				min: 1,
				required: true,
				error: "",
				example: "Temas 12 y 14",
			},
			{ 
				title: "Pregunta", 
				attr: "pregunta",  
				value: "", 
				placeholder: "Nada más producirse un accidente, antes de atender a las víctimas hay que...", 
				// label: "Examples: 40k, $20,000, $50 per hour",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Nada más producirse un accidente, antes de atender a las víctimas hay que...",
			},
			{ 
				title: "Alternativas", 
				attr: "alternativas",  
				value: "", 
				placeholder: "a) proteger y señalizar la zona del accidente.\n\nb) organizar a los servicios de urgencia.\n\nc) avisar a la policía.", 
				// label: "Examples: Microsoft CRM, Adobe, Till, Equipment",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				// min: 1,
				required: true,
				error: "",
				example: "a) proteger y señalizar la zona del accidente.\n\nb) organizar a los servicios de urgencia.\n\nc) avisar a la policía.",
			},
			{ 
				title: "Respuesta correcta", 
				attr: "respuesta",  
				value: "", 
				placeholder: "a) proteger y señalizar la zona del accidente.", 
				// label: "Examples: Tax Point Inc., Moshi Pty Ltd, Studium",
				type: "textarea",
				maxLength: 200,
				// max: 100,
				// min: 1,
				required: true,
				error: "",
				example: "a) proteger y señalizar la zona del accidente.",
			},
			{ 
				title: "Razonamiento", 
				attr: "razonamiento",  
				value: "", 
				placeholder: "Después de un accidente, la prioridad es proteger a todas las personas involucradas y señalizar la zona del accidente para evitar que se produzcan más accidentes. Esto es crucial para garantizar la seguridad de todas las personas presentes, incluidas las víctimas del accidente. Por lo tanto, la respuesta correcta es la opción a) proteger y señalizar la zona del accidente.", 
				// label: "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				// min: 1,
				// required: true,
				error: "",
				example: "Después de un accidente, la prioridad es proteger a todas las personas involucradas y señalizar la zona del accidente para evitar que se produzcan más accidentes. Esto es crucial para garantizar la seguridad de todas las personas presentes, incluidas las víctimas del accidente. Por lo tanto, la respuesta correcta es la opción a) proteger y señalizar la zona del accidente.",
			},
		],
		example: {
output: `La pregunta ha sido guardada en el sistema!

PREGUNTA
--------
Nada más producirse un accidente, antes de atender a las víctimas hay que...
a) proteger y señalizar la zona del accidente.
b) organizar a los servicios de urgencia.
c) avisar a la policía.

Respuesta:
a) proteger y señalizar la zona del accidente.

Razonamiento:
Después de un accidente, la prioridad es proteger a todas las personas involucradas y señalizar la zona del accidente para evitar que se produzcan más accidentes. Esto es crucial para garantizar la seguridad de todas las personas presentes, incluidas las víctimas del accidente. Por lo tanto, la respuesta correcta es la opción a) proteger y señalizar la zona del accidente.

Temas:
- Tema 12: La Visibilidad y Peligros de la Vía
- Tema 14: Seguridad y Conducción Económica

ANALISIS DE LA IA
-----------------
Se han encontrado las siguientes referencias a la pregunta en la documentación de la DGT:
1. "Proteger a ti mismo. No te pongas en peligro ni te arriesgues a sufrir un accidente."
2. "Señalar la zona del accidente para que se vea bien, evitar más accidentes y ayudar a los servicios de emergencia a encontrar el lugar de forma más fácil."
3. "Encender las luces de emergencia."
4. "Parar el motor y encender las luces de emergencia."

Lo que concuerda con la respuesta correcta indicada, 'a) proteger y señalizar la zona del accidente'. Antes de cualquier otra acción, la prioridad es protegerse a uno mismo para no ser víctima de un segundo accidente [1]. Después, se debe señalizar la zona del accidente para evitar que otros vehículos se vean implicados y facilitar la llegada de los servicios de emergencia [2,3,4]. Solo una vez realizadas estas primeras medidas de protección y señalización se podrá pasar a atender a las víctimas y organizar la intervención de los servicios de urgencia.
`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]
		
}

export default obj

