import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Chip,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { Edit, Delete, Visibility, FilterList } from '@mui/icons-material';
import YouTubeRangeSelector from '../YouYubeSelector/YouTubeSelector.jsx';
import { GridLoader } from 'react-spinners'


const API_BASE_URL = 'https://www.ricocorp.xyz';

const QuestionManager = ({ organizationName, userId }) => {
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('view');
  const [filters, setFilters] = useState({
    id: '',
    question: '',
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchQuestions();
  }, [organizationName]);

  useEffect(() => {
    applyFilters();
  }, [questions, filters]);


  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/questions?organization_name=${organizationName}`);
      setQuestions(response.data);
      setFilteredQuestions(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch questions. Please try again later.');
      console.error('Error fetching questions:', err);
    } finally {
      setLoading(false);
    }
  };


  const applyFilters = () => {
    let filtered = questions;

    if (filters.id) {
      filtered = filtered.filter(q => q.QuestionID.toString().includes(filters.id));
    }
    if (filters.question) {
      filtered = filtered.filter(q => q.Question.toLowerCase().includes(filters.question.toLowerCase()));
    }

    setFilteredQuestions(filtered);
  };


  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      id: '',
      question: '',
    });
  };

  const handleEditQuestion = async (question) => {
    setSelectedQuestion(question);
    setDialogMode('edit');
    setDialogOpen(true);
    setPreviewImage(question.ImageData ? `data:image/jpeg;base64,${question.ImageData}` : null);
  };

  const handleDeleteQuestion = async (question) => {
    if (!window.confirm("¿Estás seguro de que deseas eliminar esta pregunta? Si existen, también se eliminarán todos los registros de respuestas de usuarios asociados a esta pregunta, asi como preguntas aumentadas con IA basadas en ésta pregunta.")) {
      return;
    }

    setLoading(true);

    try {
      const response = await axios.delete(`${API_BASE_URL}/delete_question`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          question_id: question.QuestionID
        }
      });

      if (response.status === 200) {
        alert('Pregunta eliminada con éxito!');

        // Remove the deleted question from the questions state
        setQuestions(prevQuestions => prevQuestions.filter(q => q.QuestionID !== question.QuestionID));

        // Remove the deleted question from the filteredQuestions state
        setFilteredQuestions(prevFilteredQuestions => prevFilteredQuestions.filter(q => q.QuestionID !== question.QuestionID));

        if (selectedQuestion && selectedQuestion.QuestionID === question.QuestionID) {
          setSelectedQuestion(null);
        }
      } else {
        throw new Error('Failed to delete the question due to server error.');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      alert('Error al eliminar la pregunta.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedQuestion(null);
  };

  const handleSaveQuestion = async () => {
    try {
      const topic_ids = selectedQuestion.Topics.map(topic => topic.topic_id);

      const questionData = {
        organization_name: organizationName,
        license_type: "permiso_b",
        external_user_id: userId,
        question: selectedQuestion.Question,
        answerA: selectedQuestion.AnswerA,
        answerB: selectedQuestion.AnswerB,
        answerC: selectedQuestion.AnswerC,
        answerD: selectedQuestion.AnswerD,
        correct_answer: selectedQuestion.CorrectAnswer,
        explanation: selectedQuestion.Explanation,
        topic_ids: topic_ids,
        tags: selectedQuestion.Tags,
        level: selectedQuestion.Level,
        image_data: selectedQuestion.ImageData,
        video_ranges: selectedQuestion.VideoRanges,
      };

      const response = await axios.put(
        `${API_BASE_URL}/update_question/${selectedQuestion.QuestionID}`,
        questionData
      );

      if (response.status === 200) {
        showSnackbar('Pregunta actualizada con éxito.', 'success');
        setTimeout(() => {
          handleCloseDialog();
          fetchQuestions();
        }, 2000); // Delay closing the dialog by 1 second
      }
    } catch (error) {
      console.error('Error updating question:', error);
      let errorMessage = 'Failed to update question. Please try again.';
      if (error.response) {
        errorMessage += ` Server responded with: ${error.response.data.message || error.response.statusText}`;
      } else if (error.request) {
        errorMessage += ' No response received from server.';
      } else {
        errorMessage += ` ${error.message}`;
      }
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/delete_question/${selectedQuestion.QuestionID}`);
      if (response.status === 200) {
        handleCloseDialog();
        await fetchQuestions();
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setSelectedQuestion(prev => ({
          ...prev,
          ImageData: reader.result.split(',')[1] // Remove the data:image/jpeg;base64, part
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleViewQuestion = (question) => {
    setSelectedQuestion(question);
    setDialogMode('view');
    setDialogOpen(true);
    setPreviewImage(question.ImageData ? `data:image/jpeg;base64,${question.ImageData}` : null);
  };

  const renderQuestionDialog = () => {
    return (
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {dialogMode === 'view' && 'View Question'}
          {dialogMode === 'edit' && 'Edit Question'}
          {dialogMode === 'delete' && 'Delete Question'}
        </DialogTitle>
        <DialogContent>
          {dialogMode === 'delete' ? (
            <Typography>Está seguro de que quiere borrar esta pregunta?</Typography>
          ) : (
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Pregunta"
                value={selectedQuestion?.Question || ''}
                onChange={(e) => setSelectedQuestion({ ...selectedQuestion, Question: e.target.value })}
                disabled={dialogMode === 'view'}
                margin="normal"
                required
              />
              {['A', 'B', 'C', 'D'].map((letter) => (
                <TextField
                  key={letter}
                  fullWidth
                  label={`Respuesta ${letter}`}
                  value={selectedQuestion?.[`Answer${letter}`] || ''}
                  onChange={(e) => setSelectedQuestion({ ...selectedQuestion, [`Answer${letter}`]: e.target.value })}
                  disabled={dialogMode === 'view'}
                  margin="normal"
                  required={letter === 'A' || letter === 'B'}
                />
              ))}
              <FormControl fullWidth margin="normal">
                <InputLabel>Respuesta correcta</InputLabel>
                <Select
                  value={selectedQuestion?.CorrectAnswer || ''}
                  onChange={(e) => setSelectedQuestion({ ...selectedQuestion, CorrectAnswer: e.target.value })}
                  disabled={dialogMode === 'view'}
                  required
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                  <MenuItem value="D">D</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Explicación"
                value={selectedQuestion?.Explanation || ''}
                onChange={(e) => setSelectedQuestion({ ...selectedQuestion, Explanation: e.target.value })}
                disabled={dialogMode === 'view'}
                margin="normal"
                multiline
                rows={4}
                required
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Nivel de dificultad</InputLabel>
                <Select
                  value={selectedQuestion?.Level || ''}
                  onChange={(e) => setSelectedQuestion({ ...selectedQuestion, Level: e.target.value })}
                  disabled={dialogMode === 'view'}
                  required
                >
                  <MenuItem value={1}>1 (Verdadero/Falso)</MenuItem>
                  <MenuItem value={2}>2 (Rellenar huecos)</MenuItem>
                  <MenuItem value={3}>3 (Respuesta múltiple fácil)</MenuItem>
                  <MenuItem value={4}>4 (Respuesta múltiple TNT)</MenuItem>
                  <MenuItem value={5}>5 (Preguntas práctica)</MenuItem>
                  <MenuItem value={6}>6 (Simulación exámen)</MenuItem>
                </Select>
              </FormControl>
              <Box mt={2}>
                <Typography variant="subtitle1"><strong>Temas:</strong></Typography>
                {selectedQuestion?.Topics?.length > 0 ? (
                  <ul>
                    {selectedQuestion.Topics.map((topic, index) => (
                      <li key={index}>
                        {/* <Typography variant="body2"><strong>Topic Number:</strong> {topic.topic_number}</Typography> */}
                        <Typography variant="body2">{topic.topic_number}. {topic.topic_name}</Typography>
                        {/* <Typography variant="body2"><strong>Category:</strong> {topic.category}</Typography> */}
                        {/* <Typography variant="body2"><strong>Description:</strong> {topic.description}</Typography> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography>No hay temas disponibles para ésta pregunta.</Typography>
                )}
              </Box>
              <Box mt={2}>
                <Typography variant="subtitle1"><strong>Rangos Videos:</strong></Typography>
                {selectedQuestion?.VideoRanges?.length > 0 ? (
                  <ul>
                    {selectedQuestion.VideoRanges.map((range, index) => (
                      <li key={index}>
                        Video: {range.video_id}, Inicio: {range.start_time}s, Fin: {range.end_time}s
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography>No hay rangos de vídeos disponibles para esta pregunta.</Typography>
                )}
              </Box>
              <Box mt={2}>
                <Typography variant="subtitle1"><strong>Imagen:</strong></Typography>
                {(previewImage || selectedQuestion?.ImageData) && (
                  <img
                    src={previewImage || `data:image/jpeg;base64,${selectedQuestion.ImageData}`}
                    alt="Question"
                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                )}
                {dialogMode === 'edit' && (
                  <TextField
                    type="file"
                    onChange={handleImageChange}
                    fullWidth
                    margin="normal"
                  />
                )}
              </Box>

              <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          {dialogMode === 'edit' && <Button onClick={handleSaveQuestion}>Guardar</Button>}
          {dialogMode === 'delete' && <Button onClick={handleConfirmDelete} color="error">Borrar</Button>}
        </DialogActions>
      </Dialog>
    );
  };

  const renderFilterRow = () => (
    <TableRow>
      <TableCell>
        <TextField
          name="id"
          value={filters.id}
          onChange={handleFilterChange}
          placeholder="Filter ID"
          size="small"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          name="question"
          value={filters.question}
          onChange={handleFilterChange}
          placeholder="Filter Question"
          size="small"
          fullWidth
        />
      </TableCell>
      {/* <TableCell>
        <Select
          name="correctAnswer"
          value={filters.correctAnswer}
          onChange={handleFilterChange}
          displayEmpty
          size="small"
          fullWidth
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="A">A</MenuItem>
          <MenuItem value="B">B</MenuItem>
          <MenuItem value="C">C</MenuItem>
          <MenuItem value="D">D</MenuItem>
        </Select>
      </TableCell> */}
      {/* <TableCell>
        <Select
          name="topic"
          value={filters.topic}
          onChange={handleFilterChange}
          displayEmpty
          size="small"
          fullWidth
        >
          <MenuItem value="">All Topics</MenuItem>
          {topicsList.map((topic) => (
            <MenuItem key={topic.TopicID || topic.topic_id} value={String(topic.TopicID || topic.topic_id)}>
              {topic.TopicNumber || topic.topic_number}. {topic.TopicName || topic.topic_name}
            </MenuItem>
          ))}
        </Select>
      </TableCell> */}
      <TableCell>
        <Button
          startIcon={<FilterList />}
          onClick={clearFilters}
          size="small"
          variant="outlined"
        >
          Borrar Filtros
        </Button>
      </TableCell>
    </TableRow>
  );

  
  if (loading) {

    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh', // Adjust this height according to your needs
    };

    return (
      <div style={containerStyle}>
        <GridLoader color="#767676" size="30px" />
      </div>
    );
  } 
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      {/* <Typography variant="h4" gutterBottom>Preguntas {organizationName}</Typography> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Pregunta</TableCell>
              {/* <TableCell>Respuesta Correcta</TableCell> */}
              {/* <TableCell>Tema</TableCell> */}
              <TableCell>Acción</TableCell>
            </TableRow>
            {renderFilterRow()}
          </TableHead>
          <TableBody>
            {filteredQuestions.map((question) => (
              <TableRow key={question.QuestionID}>
                <TableCell>{question.QuestionID}</TableCell>
                <TableCell>{question.Question}</TableCell>
                {/* <TableCell>{question.CorrectAnswer}</TableCell> */}
                {/* <TableCell>
                  {question.Topics && question.Topics.map((topicId) => {
                    const topic = topicsList.find(t => t.topic_id === topicId);
                    return (
                      <Chip
                        key={topicId}
                        label={topic ? `${topic.topic_number}. ${topic.topic_name}` : topicId}
                        size="small"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    );
                  })}
                </TableCell> */}
                <TableCell>
                  <IconButton onClick={() => handleViewQuestion(question)} size="small">
                    <Visibility />
                  </IconButton>
                  <IconButton onClick={() => handleEditQuestion(question)} size="small">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteQuestion(question)} size="small">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderQuestionDialog()}
    </Box>
  );
};

export default QuestionManager;