import React from 'react';

const FrequencyIndicator = ({ level }) => {
    const gradient = "linear-gradient(to right, #add8e6, #0000ff)";
    const markerPosition = level + '%';

    return (
        <div>
            <div style={{ width: '230px', height: '20px', background: gradient, position: 'relative' }}>
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: markerPosition,
                    width: '4px',
                    height: '30px',
                    backgroundColor: 'orange',
                    transform: 'translateX(-50%)'
                }}></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
                <span><strong>Uncommon</strong></span>
                <span><strong>Always</strong></span>
            </div>
        </div>
    );
};

export default FrequencyIndicator;
