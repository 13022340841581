import * as React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography from '@mui/joy/Typography';
import Tooltip from '@mui/material/Tooltip';

function IconStepper({ currentStep }) {
  const steps = [
    { number: 1, description: 'Verdadero y Falso' },
    { number: 2, description: 'Rellenar huecos' },
    { number: 3, description: 'Respuesta Multiple 1' },
    { number: 4, description: 'Respuesta Multiple 2' },
    { number: 5, description: 'Preguntas Práctica' },
    { number: 6, description: 'Simulación Examen' },
  ];

  const activeStep = currentStep !== null && currentStep !== undefined ? currentStep : 1;

  return (
    <Stepper
      size="lg"
      sx={{
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '5px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      {steps.map((step, index) => (
        <Step
          key={index}
          completed={index < activeStep - 1}
          active={index === activeStep - 1}
          disabled={index >= activeStep}
          orientation="vertical"
          indicator={
            <Tooltip title={step.description} arrow>
              <StepIndicator variant={index === activeStep - 1 ? 'solid' : 'outlined'} color={index >= activeStep ? 'neutral' : 'primary'}>
                <Typography>{step.number}</Typography>
              </StepIndicator>
            </Tooltip>
          }
        >
          {index === activeStep - 1 && (
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'lg',
                fontSize: '0.75rem',
                letterSpacing: '0.5px',
              }}
            >
              {step.description}
            </Typography>
          )}
        </Step>
      ))}
    </Stepper>
  );
}

IconStepper.propTypes = {
  currentStep: PropTypes.number
};

IconStepper.defaultProps = {
  currentStep: 1
};

export default IconStepper;