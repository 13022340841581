import AddQuestion from '../../Components/auto/Admin';
import {
	ViewListIcon,
} from '@heroicons/react/solid'


const addQuestion = {
    title: "Creación de Preguntas", // Display name of the tool
    desc: "Da de alta nuevas preguntas.", // A brief description
    category: "Administración",
	to: "/auto/add-question",
	api: "/ai/auto/add-question",
    Icon: ViewListIcon, // Define your icon component or import it
    desc: "Da de alta nuevas preguntas.",
    fromColor: "blue-500",
    toColor: "blue-700",
    component: AddQuestion, // The React component that implements the tool
  };
  
  export default addQuestion;
  