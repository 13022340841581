import React from 'react';

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const VideoExplanations = ({ videoRanges }) => {
    if (!videoRanges || videoRanges.length === 0) return null;

    return (
        <div className="mt-4">
            {/* <h3 className="font-bold text-lg mb-2">Explicaciones en Video:</h3> */}
            {videoRanges.map((range, index) => (
                <div key={index} className="mb-4 flex flex-col items-center">
                    <iframe
                        width="63%"
                        height="315"
                        src={`https://www.youtube.com/embed/${range.video_id}?start=${range.start_time}&end=${range.end_time}`}
                        title={`Explicación en video ${index + 1}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <p className="text-sm text-gray-600 mb-1">
                        Rango de tiempo: {formatTime(range.start_time)} - {formatTime(range.end_time)}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default VideoExplanations;