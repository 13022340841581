const dev = {
	baseURL: "http://localhost:3080/api/",
	landingPageUrl: "http://localhost:3080",
	stripe: {
		free: "price_1JcQD6HIZYvvnKladKayEvOZ",
		entry: "price_1JL68HHIZYvvnKlaBJWS5uDe",
		pro: "price_1JLQhlHIZYvvnKlakrqF8khB",
		virtus_topup: "price_1PWzFa2MsGE027IGnNYlcse7"
	},
	llmApiBaseUrl: "https://www.ricocorp.xyz",
	organizationName: "RicoCorp",
	applicationName: 'VatesCognita',
	organizations: {
		Madel: {
			Logo: './Images/madel_logo.png',
			Title1: "Madel",
			Title2: "Autoescuelas",
			BoxText: "ricocorp",
			Description: "Conduciendo el futuro: Aprendizaje inteligente para conductores y educadores.",
			CreditsRemain: "creditos restantes",
			Tools: "Utilidades",
			MyProfile: "Mi Perfil",
		},
		RicoCorp: {
			Logo: './Images/ricocorp_logo.png',
			Title1: "Vates",
			Title2: "Platform",
			BoxText: "ricocorp",
			Description: "Building the Future: Custom AI Solutions for Healthcare, IT, and Businesses.",
			CreditsRemain: "credits remain",
			Tools: "Tools",
			MyProfile: "My Profile",
		},
		VedMed: {
			Logo: './Images/ricocorp_logo.png',
			Title1: "VedMed",
			Title2: "",
			BoxText: "ricocorp",
			Description: "Building the Future: Custom AI Solutions for Healthcare.",
			CreditsRemain: "credits remain",
			Tools: "Tools",
			MyProfile: "My Profile",
		},
	}
};
  
const prod = {
	baseURL: "https://ricocorp.xyz/api/",
	landingPageUrl: "https://ricocorp.xyz",
	stripe: {
		free: "price_1JcQsUHIZYvvnKlaEPy958NF",
		entry: "price_1JLpWpHIZYvvnKlaHxiCr7Js",
		pro: "price_1JLpWxHIZYvvnKlamATZ7Awm",
		virtus_topup: "price_1PWzFa2MsGE027IGnNYlcse7"
	},
	llmApiBaseUrl: "https://www.ricocorp.xyz",
	organizationName: "RicoCorp",
	applicationName: 'VatesCognita',
	organizations: {
		Madel: {
			Logo: './Images/madel_logo.png',
			Title1: "Madel",
			Title2: "Autoescuelas",
			BoxText: "ricocorp",
			Description: "Conduciendo el futuro: Aprendizaje inteligente para conductores y educadores.",
			CreditsRemain: "creditos restantes",
			Tools: "Utilidades",
			MyProfile: "Mi Perfil",
		},
		RicoCorp: {
			Logo: './Images/ricocorp_logo.png',
			Title1: "Vates",
			Title2: "Platform",
			BoxText: "ricocorp",
			Description: "Building the Future: Custom AI Solutions for Healthcare, IT, and Businesses.",
			CreditsRemain: "credits remain",
			Tools: "Tools",
			MyProfile: "My Profile",
		},
		VedMed: {
			Logo: './Images/ricocorp_logo.png',
			Title1: "VedMed",
			Title2: "",
			BoxText: "ricocorp",
			Description: "Building the Future: Custom AI Solutions for Healthcare.",
			CreditsRemain: "credits remain",
			Tools: "Tools",
			MyProfile: "My Profile",
		}
	}
};
  
const config = process.env.NODE_ENV === 'development'
	? dev
	: prod;
	
export default config; 