// images.js
import madelLogo from './Images/madel_logo.png';
import ricoCorpLogo from './Images/ricocorp_logo.png';
import vedMedLogo from './Images/vedmed_logo.png'

const imageMap = {
  Madel: madelLogo,
  RicoCorp: ricoCorpLogo,
  VedMed: vedMedLogo,
};

export default imageMap;
