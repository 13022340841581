import { ThemeProvider } from 'styled-components'
import React, { Component, useEffect } from 'react';

import { Provider } from 'mobx-react'
import { observer, } from 'mobx-react'

import AppStore from './store'
import colors from 'tailwindcss/colors' 
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useHistory,
} from "react-router-dom";

import Header from './Header'
import Search from './Search'
import Pricing from './Pricing'

import Dashboard from './Dashboard'

import Tool from './Core/Tool'
import Chat from './Core/Chat'

import Login from './Login/Login'

import Profile from './Profile/'
import LoginSuccess from './Login/Success'

import InfinitePractice from './Components/InfinitePractice';
import AngelmanSyndrome from './Components/cyto/AngelmanSyndrome';
import AddQuestion from './Components/auto/Admin';
import ResearchPaper from './Components/cyto/ResearchPaper';
import VedMedKaryotyping from './Components/cyto/VedMedKaryotyping';
import VideoGallery from './Components/auto/video_gallery/VideoGallery';
import EmbedVgpt from './Components/vGPT/EmbedVgpt'

import './App.scss'


if (!window.store) {
  window.store = new AppStore();
}

const Redirector = ({ to }) => {
  let history = useHistory();

  useEffect(() => {
    window.location.href = to;  // Direct assignment for external URLs
  }, [to]);

  return null;
};



@observer
class App extends Component {
  render() {
    return (
    <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
           {window.store.redirect ? <Redirect to={window.store.redirect} /> : null}
            {window.store.isLoggedIn ? <>
            {window.store.profile.status ? <>  {/*  Logged in with plan */}
                <Switch>
                    <Route path="/writing/document"><div /></Route>
                    <Route component={Header} />
                </Switch>
               
                <Switch>
                      
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/search" exact component={Search} />


                      <Route path="/ai/" >
                        <Switch>

                          <Route path="/ai/code/debugging" component={Chat} />
                          <Route component={Tool} />

                        </Switch>
                      </Route>
                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/signup/success" component={LoginSuccess} />

                      <Route path="/auto/infinite-practice" component={InfinitePractice} />
                  <Route path="/auto/add-question" component={AddQuestion} />
                  <Route path="/auto/video-gallery" component={VideoGallery} />


                  {/* <Route path="/cyto/karyotyping" render={() => <Redirector to="https://www.vedmed.eu/karyotyping/" />} /> */}
                  <Route path="/cyto/karyotyping" component={VedMedKaryotyping} />
                  <Route path="/cyto/angelman-syndrome" component={AngelmanSyndrome} />
                  <Route path="/edu/writing-assistant" component={ResearchPaper} />

                  {/* <Route path="/edu/vgpt" render={() => <Redirector to="http://localhost:5179/vgpt" />} /> */}
                  <Route path="/edu/vgpt" component={EmbedVgpt} />


                  </Switch>
                  </> : <> {/* Logged in but no plan */}

               <Switch>
                  <Route path="/signup/success" component={LoginSuccess} />
                  <Route>
                    <Pricing />
                  </Route>
                  </Switch>
                </>} </> : <> {/*  Not Logged In */}
                <Switch>
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
            </>}
           </Router>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default App