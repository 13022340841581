import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import {
  ChevronLeftIcon,
} from '@heroicons/react/outline';
import './VideoPlayer.css';
import VideoQuestions from './VideoQuestions';


const VideoPlayer = ({ video, onBack }) => {
  const [videoId, setVideoId] = useState(null);
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const playerRef = useRef(null);
  const textAreaRef = useRef(null);


  useEffect(() => {
    const extractedId = extractVideoId(video.url);
    setVideoId(extractedId);
  }, [video.url]);

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const handleProgress = (state) => {
    setPlayedPercentage(state.played * 100);
  };


  return (
    <div>
      <div className="bg-white md:px-8 pt-4 shadow-lg mb-px border-b border-gray-300">
        <div className="container mx-auto px-4 md:px-28 flex items-center justify-between">
          <Option title={"Volver a Galería"} Icon={ChevronLeftIcon} onClick={onBack} color="yellow" />
        </div>
      </div>
      <div className="video-player-container">
        <div className="player-wrapper">
          <ReactPlayer 
            ref={playerRef}
            url={video.url}
            controls
            className="react-player"
            width="100%"
            height="100%"
            onProgress={handleProgress}
          />
          <h2 className="video-player-title">{video.title}</h2>
        </div>
      </div>
      {videoId && playedPercentage >= 80 && <VideoQuestions videoId={videoId} />}
    </div>
  );
};

export function Option({ title, Icon, onClick, color }) {
  return (
    <div className={`lg:py-2 lg:px-6 py-3 px-6 flex transition text-gray-500 font-medium border-b -mb-px rounded-t-lg border-gray-400 bg-white hover:bg-gray-100 cursor-pointer`} onClick={onClick}>
      <div className={`md:mr-1 transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-gray-300 text-gray-600`}>
        <Icon className={`h-4 w-4 text-gray-600`} aria-hidden="true" />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default VideoPlayer;
