import EmbedVgpt from '../../Components/vGPT/EmbedVgpt'
import {
	ViewListIcon,
} from '@heroicons/react/solid'


const virtusGPT = {
    title: "Writing Assistant",
    desc: "OpenAI's ChatGPT clone with access to additional models and features.",
    category: "Virtus",
    permissions: ['virtus', 'cyto'],
	to: "/edu/vgpt",
    Icon: ViewListIcon,
    fromColor: "blue-700",
    component: EmbedVgpt,
  };
  
  export default virtusGPT;
  