import React, { useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.h2`
  text-align: left;
  color: #2c3e50;
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const VideoContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TimeControl = styled.div`
  display: flex;
  align-items: center;
`;

const TimeInput = styled.input`
  width: 70px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const RangeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
`;

const RangeItem = styled.li`
  background-color: white;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`;

const RangeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoName = styled.span`
  font-weight: bold;
  margin-bottom: 0.3rem;
`;

const TimesInfo = styled.span`
  color: #666;
`;

const RemoveButton = styled(Button)`
  background-color: #e74c3c;
  padding: 0.3rem 0.6rem;
  font-size: 0.9rem;
  &:hover {
    background-color: #c0392b;
  }
`;

const SaveAllButton = styled(Button)`
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1.1rem;
  background-color: #2ecc71;

  &:hover {
    background-color: #27ae60;
  }
`;

const YouTubeRangeSelector = ({ onRangesChange, initialRanges = [] }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoId, setVideoId] = useState('');
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [selectedRanges, setSelectedRanges] = useState(initialRanges);
  const playerRef = useRef(null);

  const getVideoName = (videoId) => {
    // In a real application, you might want to fetch the actual video title
    // For now, we'll just use a shortened version of the video ID
    return `Video: ${videoId.substring(0, 50)}...`;
  };

  useEffect(() => {
    if (videoUrl) {
      const id = extractVideoId(videoUrl);
      setVideoId(id);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (initialRanges.length > 0) {
      setSelectedRanges(initialRanges);
    }
  }, [initialRanges]);

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const handleVideoReady = (event) => {
    playerRef.current = event.target;
    setDuration(playerRef.current.getDuration());
  };

  const handleSetStartTime = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      setStartTime(Math.floor(currentTime));
    }
  };

  const handleSetEndTime = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      setEndTime(Math.floor(currentTime));
    }
  };

  const handleAddRange = () => {
    if (!videoId) {
      alert('Por favor introduzca una URL válida.');
      return;
    }

    let rangeToAdd;

    if (startTime === 0 && endTime === 0) {
      // If both start and end times are 0, add the full video range
      rangeToAdd = { videoId: videoId, startTime: 0, endTime: Math.floor(duration) };
    } else if (startTime >= endTime) {
      alert('Por favor introduzca un rango de tiempos válido.');
      return;
    } else {
      rangeToAdd = { videoId: videoId, startTime: startTime, endTime: endTime };
    }

    const updatedRanges = [...selectedRanges, rangeToAdd];
    setSelectedRanges(updatedRanges);
    onRangesChange(updatedRanges);
    setStartTime(0);
    setEndTime(0);
  };
  

  const handleRemoveRange = (index) => {
    const updatedRanges = selectedRanges.filter((_, i) => i !== index);
    setSelectedRanges(updatedRanges);
    onRangesChange(updatedRanges);
  };

  return (
    <Container>
      <Title>Selector de videos</Title>
      <Input
        type="text"
        value={videoUrl}
        onChange={(e) => setVideoUrl(e.target.value)}
        placeholder="Introduzca link de YouTube"
      />
      {videoId && (
        <VideoContainer>
          <YouTube
            videoId={videoId}
            opts={{ width: '100%', height: '315' }}
            onReady={handleVideoReady}
          />
          <ControlsContainer>
            <TimeControl>
              <TimeInput
                type="number"
                value={startTime}
                onChange={(e) => setStartTime(Number(e.target.value))}
                min={0}
                max={duration}
              />
              <Button onClick={handleSetStartTime}>Marcar Inicio</Button>
            </TimeControl>
            <TimeControl>
              <TimeInput
                type="number"
                value={endTime}
                onChange={(e) => setEndTime(Number(e.target.value))}
                min={0}
                max={duration}
              />
              <Button onClick={handleSetEndTime}>Marcar Final</Button>
            </TimeControl>
          </ControlsContainer>
          <Button onClick={handleAddRange} style={{ width: '100%' }}>
            {startTime === 0 && endTime === 0 ? 'Añadir video completo' : 'Añadir rango'}
          </Button>
        </VideoContainer>
      )}
      <RangeList>
        {selectedRanges.map((range, index) => (
          <RangeItem key={index}>
            <RangeInfo>
              <VideoName>{getVideoName(range.videoId)}</VideoName>
              <TimesInfo>
                {range.startTime === 0 && range.endTime === Math.floor(duration)
                  ? 'Video completo'
                  : `${range.startTime}s - ${range.endTime}s`}
              </TimesInfo>
            </RangeInfo>
            <RemoveButton onClick={() => handleRemoveRange(index)}>Eliminar</RemoveButton>
          </RangeItem>
        ))}
      </RangeList>
    </Container>
  );
};

export default React.memo(YouTubeRangeSelector);


YouTubeRangeSelector.propTypes = {
  onRangesChange: PropTypes.func.isRequired,
  initialRanges: PropTypes.arrayOf(PropTypes.shape({
    videoId: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
  })),
};