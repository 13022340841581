import React, { Component } from 'react';
import {
    PencilIcon,
    ClockIcon,
    InformationCircleIcon,
    DuplicateIcon,
    ViewListIcon,
} from '@heroicons/react/outline'

import { Helmet } from "react-helmet";

import HeaderImg from '../../Components/cyto/HeaderAngelmanSyndrome'
import Body, { Grid, Col } from '../../Components/Body'
import Button from '../../Components/Button'
import Output from '../../Components/Output'
import Countdown from 'react-countdown';
// import { withRouter } from 'react-router-dom'

import { observable, makeObservable, computed, } from 'mobx'
import { observer, inject, } from 'mobx-react'

import EntryTabs from '../../Components/EntryTabs'
import EntryPrompt from '../../Components/EntryPrompt'
import EntryInput from '../../Components/EntryInput'
import EntryN from '../../Components/EntryN'

import Filter from 'bad-words'

import Image from '../../Components/cyto/img/ang_syn.png'
import AngelmanSyndromeInfo from '../cyto/AngelmanSyndromeInfo'

import axios from 'axios';


let filterBadWords = new Filter()

@inject('store')
@observer
class AngelmanSyndrome extends Component {

    @observable tool = {}

    @observable.deep prompts = []
    @observable currentPrompt = 0
    @observable currentOption = "Start Using"

    @observable error = ""

    @observable output = ""
    @observable outputs = []
    @observable code = ""


    @observable loading = false

    @observable date = Date.now() + 1000
    countdown = []

    constructor(props) {
        super(props);
        makeObservable(this);

        // const output_references = `**References**
        // 1. "In Angelman syndrome, there is a gene on chromosome 15 that is missing or defective.". [AngelmanSyndrome Foundation_sindrome_de_angelman_book.pdf]
        // 2. "Angelman syndrome can occur due to a microdeletion of region 15q11.2-q13 (60 to 75% of cases), paternal uniparental disomy (2 to 5% of cases), imprinting defects (2 to 5%), and mutations in the UBE3A gene (10% of cases).". [Stem_cell_models_of_Angelman_syndrome.pdf]
        // 3. "In approximately 5 to 26% of those affected, the genetic anomalies remain unidentified.". [Social attention and emotional modulation in Angelman syndrome_eye‐tracking study.pdf]
        // `
        // const output_answer = `**Answer**
        // According to the documentation, Angelman Syndrome can be caused by four main genetic mechanisms:
        // 1. Microdeletions in region 15q11.2-q13, accounting for 60-75% of cases. [1,2]
        // 2. Paternal uniparental disomy (UPD) of chromosome 15, accounting for 2-5% of cases. [2]
        // 3. Imprinting defects in region 15q11.2-q13, accounting for 2-5% of cases. [2]
        // 4. Mutations in the UBE3A gene, accounting for 10% of cases. [2]
        // `

        // Define the tool data directly in the constructor
        this.tool = {
            title: "Angelman Syndrome AI",
            desc: "Dive deep into the understanding of Angelman Syndrome.",
            category: "AS.ai",
            Icon: ViewListIcon,
            permissions: ['all', 'cyto'],
            fromColor: "green-500",
            toColor: "blue-500",
            to: "/cyto/angelman-syndrome",
            api: "/ai/cyto/angelman-syndrome",
            output: {
                title: "Insights on Angelman Syndrome",
                desc: "Here's what we found:",
                Icon: false,
                color: "indigo",
            },
            prompts: [{
                title: "Query",
                desc: "Ask any question about Angelman Syndrome, its symptoms, genetics, or treatments.",
                prompts: [{
                    title: "Question",
                    attr: "content",
                    value: "",
                    placeholder: "What are the genetic causes of Angelman Syndrome?",
                    type: "textarea",
                    maxLength: 600,
                    min: 3,
                    required: true,
                    error: "",
                    example: "What are the genetic causes of Angelman Syndrome?"
                }],
                example: {
                    "output": "REFERENCES\n1. \"In Angelman syndrome, there is a gene on chromosome 15 that is missing or defective.\". [AngelmanSyndrome Foundation_sindrome_de_angelman_book.pdf]\n2. \"Angelman syndrome can occur due to a microdeletion of region 15q11.2-q13 (60 to 75% of cases), paternal uniparental disomy (2 to 5% of cases), imprinting defects (2 to 5%), and mutations in the UBE3A gene (10% of cases).\". [Stem_cell_models_of_Angelman_syndrome.pdf]\n3. \"In approximately 5 to 26% of those affected, the genetic anomalies remain unidentified.\". [Social attention and emotional modulation in Angelman syndrome_eye‐tracking study.pdf]\n\nANSWER\nAccording to the documentation, Angelman Syndrome can be caused by four main genetic mechanisms:\n\n1. Microdeletions in region 15q11.2-q13, accounting for 60-75% of cases. [1,2]\n2. Paternal uniparental disomy (UPD) of chromosome 15, accounting for 2-5% of cases. [2]\n3. Imprinting defects in region 15q11.2-q13, accounting for 2-5% of cases. [2]\n4. Mutations in the UBE3A gene, accounting for 10% of cases. [2]",
                    // outputs: [output_references, output_answer],
                    "color": "indigo"
                }
            }]
        };

        // Set prompts from the tool data
        this.prompts = [...this.tool.prompts];
    }

    @observable showAngelmanInfo = false;

    handleInfoClick = (option) => {
        if (option === "Learn More") {
            this.showAngelmanInfo = true;
            this.currentOption = "Learn More"; // Set current option to Learn More
        } else if (option === "Start Using") {
            this.showAngelmanInfo = false;
            this.currentOption = "Start Using"; // Set current option to Start Using
        }
    };

    handleCurrentPrompt = (val) => {
        this.currentPrompt = val
    }

    @computed get isGenerateButtonDisabled() {



        if (this.loading) {
            return true
        }

        return false
    }

    @computed get disabled() {

        if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
            return true
        }


        // this.prompts[this.currentPrompt].prompts[promptIndex].value
        return false
    }

    @computed get isMinLength() {

        if (!this.props.prompt.min) {
            return false
        }
        if (!this.props.prompt.type === "number") {
            return false
        }

        return false
    }

    checkMinimumPrompts = () => {

        let shouldReturn = false

        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            if (prompt.min) {
                if (prompt.value.length < prompt.min) {
                    shouldReturn = true
                    prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
                }
            }
        })

        return shouldReturn
    }


    clearExampleTimeout = []

    onStartUsing = async () => {
        this.loading = false
        this.error = ""
        this.clearExampleTimeout.forEach((item, index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })
        this.currentOption = "Start Using"
    }

    onExample = async () => {
        this.loading = true
        this.error = ""
        this.output = ""
        this.outputs = []
        this.code = ``

        this.currentOption = "Example"

        let totalLength = 0;

        this.clearExampleTimeout.forEach((item, index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })

        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            this.prompts[this.currentPrompt].prompts[promptIndex].value = ""
        })

        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            for (let timeoutIndex = 0; timeoutIndex < prompt.example.length; timeoutIndex++) {
                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(() => {
                    this.prompts[this.currentPrompt].prompts[promptIndex].value += prompt.example[timeoutIndex]
                }, 7 * totalLength)
            }
        })



        totalLength++

        if (this.prompts[this.currentPrompt].example.output) {
            this.clearExampleTimeout[totalLength] = setTimeout(() => {
                this.output = this.prompts[this.currentPrompt].example.output
                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(() => {
                    this.loading = false
                    this.currentOption = "Start Using"
                    this.prompts[this.currentPrompt].prompts[0].value += " "
                }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500)

            }, (7 * totalLength) + 500)
        }

        if (this.prompts[this.currentPrompt].example.code) {
            totalLength++
            this.clearExampleTimeout[totalLength] = setTimeout(() => {
                this.code = `${this.prompts[this.currentPrompt].example.code}`
                this.loading = false
            }, (7 * totalLength) + 500)
        }

        if (this.prompts[this.currentPrompt].example.outputs) {
            this.clearExampleTimeout[totalLength] = setTimeout(() => {
                this.outputs = this.prompts[this.currentPrompt].example.outputs

                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(() => {
                    this.loading = false
                    this.currentOption = "Start Using"
                    // this.prompts[this.currentPrompt].prompts[0].value += " "
                }, 7 * totalLength + 500)

            }, (7 * totalLength) + 500)
        }


    }


    sanitizeAllPrompts = () => {
        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            if (!prompt.value) {
                return false;
            }
            if (prompt.type === "number") {
                return false;
            }

            prompt.value = prompt.value.trim()

            if (filterBadWords.isProfane(prompt.value)) {
                prompt.error = "Unsafe content , please try different language"
                throw Error("Unsafe content")
            }
        })
    }

    contentFilterFlagged = async (response) => {
        this.error = response.message

        this.date = Date.now() + 5000
        this.countdown.forEach(countdown => {
            if (countdown) {
                countdown.stop()
                countdown.start()
            }
        })
        this.loading = false
    }

    checkOutput = (output) => {
        if (output) {
            output = output.replace(/^\s+|\s+$/g, '')
            // output = output.replace(/\s{2,}/g, ' ')
        }
        return output
    }

    @computed get language() {
        let language = ""
        this.prompts[this.currentPrompt].prompts.forEach(prompt => {
            if (prompt.attr === "language") {
                language = `${prompt.value}`
            }
        })
        return language
    }


    onGenerateClick = async () => {
        console.log("Generate button clicked");
        try {
            this.error = ""
            this.output = ""
            this.code = ``
            this.outputs = []
            this.loading = true

            let checkMinimumPrompts = this.checkMinimumPrompts()
            if (checkMinimumPrompts) {
                this.loading = false
                return false
            }
            // this.sanitizeAllPrompts()

            let postObj = {}

            // Cada prompt corresponde a una pestaña
            this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                postObj[prompt.attr] = prompt.value
            })

            postObj.currentPrompt = this.prompts[this.currentPrompt].title
            if (this.prompts[this.currentPrompt].n) {
                postObj.n = this.prompts[this.currentPrompt].n
            }

            // let content = this.prompts[this.currentPrompt].value
            let content = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content').value;

            const userID = this.props.store.profile._id
            const userEmail = this.props.store.profile.email
            const apiBaseUrl = this.props.store.llmApiBaseUrl
            const payload = {
                question: content,
                organization_name: this.props.store.organizationName,
                user_name: userEmail,
                assistant_name: "RAG Angelman Syndrome",
                application_name: this.props.store.applicationName,
            };

            const headers = {
                "Content-Type": "application/json",
                "X-Api-Key": "xxx", // Replace 'xxx' with your actual API key
            };

            try {
                const apiUrl = `${apiBaseUrl}/users/${userID}/chats/${userID}-C4/rag_assistant_question`;
                const response = await axios.post(apiUrl, payload, { headers });
                if (response.status === 200 && response.data) {
                    this.output = response.data.Answer;
                    // this.outputs.push(response.data.Answer); // To keep a history of outputs
                } else {
                    this.error = "Failed to fetch the answer.";
                }
            } catch (error) {
                this.error = error.message || "An error occurred while fetching the answer.";
            }

            this.date = Date.now() + 10000
            this.countdown.forEach(countdown => {
                if (countdown) {
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        } catch (error) {
            console.log(error)
            this.countdown.forEach(countdown => {
                if (countdown) {
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        }
    }

    render() {

        // required for mobx to pick up deeply nested value 
        const currentValue = this.prompts[this.currentPrompt].prompts[0].value

        return (
            <>
                <Helmet>
                    <title>{`${this.tool.title}`}</title>
                </Helmet>
                <HeaderImg
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={this.tool.Icon}
                    fromColor={this.tool.fromColor}
                    category={this.tool.category}

                    options={[
                        {
                            title: "Start Using",
                            Icon: PencilIcon,
                            color: this.props.store.profile.credits ? 'green' : 'red',
                            onClick: () => this.handleInfoClick("Start Using")
                        },
                        { title: "Example", color: 'yellow', Icon: InformationCircleIcon, onClick: this.onExample },
                    ]}
                    currentOption={this.currentOption}
                    onInfoClick={() => this.handleInfoClick("Learn More")}
                    image={{ url: Image, alt: "Angelman Syndrome examples" }}
                />
                <Body>
                    {this.currentOption === "Learn More" ? <AngelmanSyndromeInfo /> : (
                        <Grid>
                            <Col span="6">

                                <EntryTabs
                                    prompts={this.prompts}
                                    currentPrompt={this.currentPrompt}
                                    onChange={this.handleCurrentPrompt}
                                />

                                {this.prompts.map((prompt, index) =>
                                    <EntryPrompt
                                        prompt={prompt}
                                        key={index}
                                        index={index}
                                        disabled={this.disabled}
                                        currentPrompt={this.currentPrompt}
                                    >
                                        {prompt.prompts.map((promptInput, index) =>
                                            <EntryInput
                                                prompt={promptInput}
                                                key={index}
                                                language={this.language}
                                                index={index}
                                                disabled={this.disabled}
                                            />)}


                                        <div className="md:flex">
                                            <Countdown
                                                ref={countdown => this.countdown[index] = countdown}
                                                date={this.date}
                                                renderer={props =>
                                                    <Button
                                                        title={props.total ? `Timeout ${props.total / 1000} secs` : "Perform Request"}
                                                        disabled={props.total || this.isGenerateButtonDisabled}
                                                        Icon={props.total ? ClockIcon : currentValue ? DuplicateIcon : PencilIcon}
                                                        onClick={this.onGenerateClick}
                                                    />}
                                            />
                                            <EntryN
                                                prompts={this.prompts}
                                                currentPrompt={this.currentPrompt}
                                            />
                                        </div>


                                        {this.error && <div className="mt-4"><label
                                            className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>
                                            {this.error}
                                        </label></div>}

                                    </EntryPrompt>
                                )}

                            </Col>
                            <Col span="6">
                                <Output
                                    title={this.tool.output.title}
                                    desc={this.tool.output.desc}

                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
                                    toColor={this.tool.toColor}

                                    loading={this.loading}
                                    output={this.output}
                                    // outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}

                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                                />
                            </Col>
                        </Grid>
                    )}
                </Body>
            </>
        )
    }
}


export default AngelmanSyndrome