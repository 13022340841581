import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ApiStore from '../../../api/ApiStore';
import InfinitePractice from '../../InfinitePractice';
import './VideoQuestions.css';

const VideoQuestions = observer(({ videoId }) => {
  const [questionIds, setQuestionIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuestionIds = async () => {
      try {
        setLoading(true);
        const response = await ApiStore.getQuestionIdsForVideo(videoId);
        setQuestionIds(response.question_ids);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch question IDs');
        setLoading(false);
      }
    };

    fetchQuestionIds();
  }, [videoId]);

  if (loading) return <div>Loading questions...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='infinite-practice-container'>
      <div className="">
        {questionIds.length > 0 ? (
          <div className="text-center">
            <InfinitePractice questionIds={questionIds} showChapterGrid={false}/>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl text-gray-700 font-semibold">
              No hay preguntas disponibles para este video.
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

export default VideoQuestions;