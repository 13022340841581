import ResearchPaper from '../../Components/cyto/ResearchPaper';
import {
	ViewListIcon,
} from '@heroicons/react/solid'


const researchPaper = {
    title: "Writing Assistant",
    desc: "Improve your documents for clarity and style while maintaining factual accuracy and avoiding plagiarism.",
    category: "Flumen",
	to: "/edu/writing-assistant",
	api: "",
    Icon: ViewListIcon,
    fromColor: "gray-500",
    toColor: "blue-700",
    component: ResearchPaper,
  };
  
  export default researchPaper;
  