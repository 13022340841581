import React from 'react';
import Facial from "../cyto/img/ang_syn_facial_expressions.png";
import Classes from "../cyto/img/genetic-classes-of-as.jpg";
import FrequencyIndicator from "../FrequencyIndicator"

const AngelmanSyndromeInfo = () => {
    return (
        <div className="p-4 bg-white rounded shadow">
            <h1 className="text-xl font-bold text-gray-800">Angelman Syndrome AI</h1>
            <p className="text-gray-600 mt-4">
                Introducing the Angelman Syndrome AI, a revolutionary resource designed specifically for geneticists seeking the most current and comprehensive information on Angelman Syndrome. This advanced AI tool consolidates the latest research, including detailed prevalence rates, diagnostic criteria, and genetic causes, along with a deep dive into the associated developmental and physical characteristics of the syndrome. With frequent updates and access to a wealth of data, geneticists can leverage this tool to stay at the forefront of research developments, enhance diagnostic accuracy, and tailor treatment plans effectively. Whether you're conducting genetic research, diagnosing patients, or developing new therapies, the Angelman Syndrome AI Tool offers a reliable, accessible, and interactive way to enhance your work and contribute to the global understanding of this complex genetic disorder.
            </p>


            <h1 className="text-xl font-bold text-gray-800 my-6">Angelman Syndrome</h1>
            <p className="text-gray-600 mt-4">
                Angelman syndrome is a genetic disorder causing developmental disabilities
                and nerve-related symptoms. It's usually detected in children between 6 to 12 months with symptoms such as lack of crawling or babbling.
            </p>
            <img src={Facial} alt="Angelman Syndrome Facial Expressions" className="mt-4 w-3/5 h-auto" style={{ display: 'block', margin: 'auto' }} />

            <p className="text-gray-600 mt-4">
                The condition primarily affects the nervous system with symptoms like delayed development,
                intellectual disability, severe speech impairment, and problems with movement and balance.
            </p>

            <h1 className="text-xl font-bold text-gray-800 my-6">Prevalence of Angelman Syndrome</h1>
            <p className="text-gray-600 mt-4">
                The prevalence of Angelman Syndrome is estimated at about 1/12,000 to 1/10,000, with a recommended estimate
                of 1/15,000 for general use. This statistical data is based on studies conducted in Sweden and Denmark.
            </p>

            <h1 className="text-xl font-bold text-gray-800 my-6">Diagnostic Criteria</h1>
            <p className="text-gray-600 mt-4">
                Diagnosis is challenging in infants due to non-specific developmental issues but is typically made between the ages
                of 2 and 5 when characteristic behaviors and traits become more apparent.
            </p>

            <h1 className="text-xl font-bold text-gray-800 my-6">Symptoms</h1>
            <table className="min-w-full table-auto text-gray-600 mt-4">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="px-4 py-2">Medical Term</th>
                        <th className="px-4 py-2">Description and Symptoms</th>
                        <th className="px-4 py-2">Frequency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Sleep disturbance</strong></td>
                        <td className="border px-4 py-2">An unintentional, oscillating to-and-fro muscle movement about a joint axis.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Tremor; Tremors</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Tremor</strong></td>
                        <td className="border px-4 py-2">An abnormality of sleep including such phenomena as <br></br>1) insomnia/hypersomnia<br></br>2) non-restorative sleep<br></br>3) sleep schedule disorder<br></br>4) excessive daytime somnolence<br></br>5) sleep apnea<br></br>6) restlessness.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Difficulty sleeping; Sleep disturbances; Sleep dysfunction; Trouble sleeping</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Severe global developmental delay</strong></td>
                        <td className="border px-4 py-2">A severe delay in the achievement of motor or mental milestones in the domains of development of a child.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Global developmental delay, severe</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Self-injurious behavior</strong></td>
                        <td className="border px-4 py-2">Aggression towards oneself.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Autoagression; Self injury; Self-harm; Self-injurious behavior; Self-injurious behaviour</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Seizure</strong></td>
                        <td className="border px-4 py-2">A seizure is an intermittent abnormality of nervous system physiology characterised by a transient occurrence of signs and/or symptoms due to abnormal excessive or synchronous neuronal activity in the brain.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Epileptic seizure; Seizures</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Poor speech</strong></td>
                        <td className="border px-4 py-2">Poor speech</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Neurological speech impairment</strong></td>
                        <td className="border px-4 py-2">A type of Developmental delay characterized by a delay in acquiring motor skills.
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Delay in motor development; Delayed early motor milestones; Delayed motor development; Delayed motor milestones; Locomotor delay; Motor developmental delay; Motor developmental milestones not achieved; Motor retardation; Retarded motor development</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={80} /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2" style={{ fontSize: '18px' }}><strong>Wide mouth</strong></td>
                        <td className="border px-4 py-2">Distance between the oral commissures more than 2 SD above the mean. Alternatively, an apparently increased width of the oral aperture (subjective).
                            <br></br>
                            <br></br>
                            <strong>Synonyms</strong>: Broad mouth; Large mouth; Large oral aperture; Macrostomia; Wide mouth</td>
                        <td className="border px-4 py-2"><FrequencyIndicator level={50} /></td>
                    </tr>
                </tbody>
            </table>


            <h1 className="text-xl font-bold text-gray-800 my-6">Genetic Causes</h1>
            <p className="text-gray-600 mt-4">
                Mutations in the UBE3A gene located on chromosome 15 are known to cause Angelman Syndrome.
                These genetic disruptions lead to the absence of function of this gene from the maternal chromosome 15.
            </p>
            <img src={Classes} alt="Genetic Classes of AS" className="mt-4 w-3/5 h-auto" style={{ display: 'block', margin: 'auto' }} />
            <p className="text-gray-600 mt-4">
                A chromosome 15 pair is illustrated for each class depicted but the other chromosomes are not shown. The P indicates the maternally-derived chromosome and the M indicates the maternally-derived one. The shaded chromosomes have a paternal pattern of gene functioning while the unshaded chromosomes have a maternal pattern. AS can be caused by either a large chromosome deletion (70% of the time); a disruptive mutations in the UBE3A gene inherited from the mother (indicated by the X); inheritance from the father of 2 normal number 15 chromosomes (e.g., paternal uniparental disomy [UPD]); or an imprinting defect (ID), occurring when the chromosome 15 inherited from the mother has the paternal pattern of gene functioning because of a problem in the imprinting center (denoted by the small open circle).
            </p>

            <h1 className="text-xl font-bold text-gray-800 my-6">Sources</h1>
            <ul className="list-disc pl-8 text-gray-600">
                <li>
                    <a href="https://rarediseases.info.nih.gov/diseases/5810/angelman-syndrome" target="_blank" rel="noopener noreferrer">
                        Genetic and Rare Diseases Information Center - National Center for Advancing Translational Sciences.
                    </a>
                </li>
                <li>
                    <a href="https://www.angelman.org/wp-content/uploads/2015/11/sindrome_de_angelman_3_29_10.pdf" target="_blank" rel="noopener noreferrer">
                        Angelman Syndrome Foundation: Información acerca del síndrome de Angelman. 7th Edition. January 2009.
                    </a>
                </li>
                <li>
                    <a href="https://www.angelmantoday.com/understanding-genetic-classes/" target="_blank" rel="noopener noreferrer">
                        Understanding Genetic Classes of Angelman Syndrome – By Dr. Charles Williams.
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default AngelmanSyndromeInfo;
