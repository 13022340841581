import React, { Component } from 'react';
import {
    PencilIcon,
    ClockIcon,
    InformationCircleIcon,
    DuplicateIcon,
    ClipboardListIcon,
} from '@heroicons/react/outline'

import { Helmet } from "react-helmet";

import HeaderPaper from './HeaderPaper'
import Body, { Grid, Col } from '../Body'
import Button from '../Button'
import OutputSmallText from '../OutputSmallText'
import OutputTextEditor from '../OutputTextEditor'
import ResearchPaperSectionsOutput from './ResearchPaperSectionsOutput'
import Countdown from 'react-countdown';

import { observable, makeObservable, computed, } from 'mobx'
import { observer, inject, } from 'mobx-react'

import EntryTabs from '../EntryTabs'
import EntryPrompt from '../EntryPrompt'
import EntryInput from '../EntryInput'
import EntryN from '../EntryN'

import Filter from 'bad-words'

import TextEditor from '../TextEditor'

import axios from 'axios';
import RichTextEditor from 'react-rte';

import TipTapEditor from '../TipTapEditor/TipTapEditor';



let filterBadWords = new Filter()


@inject('store')
@observer
class ResearchPaper extends Component {

    @observable tool = {}

    @observable.deep prompts = []
    @observable currentPrompt = 0
    @observable currentOption = "Sections"

    @observable error = ""

    @observable output = ""
    @observable outputs = []
    @observable code = ""


    @observable loading = false

    @observable date = Date.now() + 1000
    countdown = []

    @observable selectedText = "";



    handleEditorChange = (value) => {
        this.setState({ value });
    };

    constructor(props) {
        super(props);
        makeObservable(this);



        this.state = {
            value: RichTextEditor.createEmptyValue(),
        };

        const output1 = `Feedback

Your tentative title, 'Genetic aspects of mouse leukemia', is clear and straightforward, which is beneficial in scientific communication. However, it could be more specific to reflect the advanced and specific nature of your research, particularly focusing on chronic lymphocytic leukemia (CLL) and the use of novel genetic engineering technologies in mouse models. A more specific title can attract the right audience and convey the importance and uniqueness of your study, potentially increasing its impact and engagement within the scientific community."`;
        const output2 = `Alternatives

- 'Advancing CLL Understanding through Genetic Engineering in Mouse Models'
- 'Genetic Engineering and Mouse Models: Mimicking CLL Progression and Variations'
`;

        // Define the tool data directly in the constructor
        this.tool = {
            title: "Writing",
            desc: "Quickly draft any type of document for clarity and style while maintaining factual accuracy and avoiding plagiarism.",
            category: "Flumen",
            Icon: ClipboardListIcon,
            permissions: ['all', 'cyto'],
            fromColor: "blue-600",
            toColor: "yellow-500",
            to: "/cyto/research-paper",
            api: "/ai/cyto/research-paper",
            output: {
                title: "Output",
                desc: "Here are some ideas:",
                Icon: false,
                color: "indigo",
            },
            prompts: [
                {
                    title: "Layout",
                    desc: "Design the layout of your document.",
                    prompts: [{
                        title: "Document Type",
                        attr: "content",
                        value: "",
                        placeholder: "Research Paper",
                        label: `[ACADEMIC] Researh Paper, Theses, Dissertation, Literature Review, Lab Report, Case Study
                        [EDUCATIONAL] Lecture Notes, Study Guides, Course Syllabi, Presentation Slides
                        [ADMINISTRATIVE] Grant Proposals, Curriculum vitae (CV), Letter of Recommendation, Conference Proposals
                        [OTHER] Book Review, Annotated Bibliography, Reflective Essay`,
                        type: "text",
                        maxLength: 50,
                        min: 3,
                        required: true,
                        error: "",
                        example: "Research Paper"
                    },
                    {
                        title: "Additional requests",
                        attr: "content",
                        value: "",
                        placeholder: "",
                        type: "textarea",
                        maxLength: 600,
                        required: true,
                        error: "",
                        example: ""
                    }
                    ],
                    example: {
                        "output": `Research Paper Title
Author: Author Name
Institution: University Name
Date: Submission Date

Abstract
This section should provide a brief summary of the research question, methodology, results, and conclusions. It should be succinct and informative, designed to give the reader a quick overview of the paper.

Introduction
This section introduces the background of the research topic, outlines the research problem, and states the objectives of the study. It should also provide a brief overview of the paper's structure.

Methodology
This part describes the research design, data sources, analytical techniques, and procedures used in the study. It should be detailed enough for the study to be reproducible.

Results
Here, present the findings of the study without interpretation. Use figures, tables, and graphs to support the data collected.

Discussion
Discuss the implications of the results and how they fit into the context of existing research. Mention any limitations of the study and propose areas for future research.

Conclusion
Summarize the findings, restate the significance of the research, and highlight any potential applications of the work. This section should reinforce why the research matters.

References
List all the scholarly works cited in the document. Ensure each reference is formatted according to the specified citation style (e.g., APA, MLA, Chicago).

Research Paper by Author Name, University Name
                        `,
                        "color": "indigo"
                    }
                },
                {
                    title: "Title",
                    desc: "Design an impactful and engaging title.",
                    prompts: [{
                        title: "Title",
                        attr: "content",
                        value: "",
                        placeholder: "Genetic aspects of mouse leukemia",
                        label: "The title you were planning to give the paper.",
                        type: "text",
                        maxLength: 150,
                        min: 5,
                        required: true,
                        error: "",
                        example: "Genetic aspects of mouse leukemia"
                    },
                    {
                        title: "Context",
                        attr: "content",
                        value: "",
                        placeholder: "A review that describes how advanced mouse models, employing new genetic engineering technologies, enhance the understanding and treatment of chronic lymphocytic leukemia (CLL) by mimicking its genetic variations and progression.",
                        label: "A short description of what the paper will be about.",
                        type: "textarea",
                        maxLength: 600,
                        min: 3,
                        required: true,
                        error: "",
                        example: "I made a review that describes how advanced mouse models, employing new genetic engineering technologies, enhance the understanding and treatment of chronic lymphocytic leukemia (CLL) by mimicking its genetic variations and progression."
                    },
                    {
                        title: "Key words",
                        attr: "content",
                        value: "",
                        placeholder: "genetics, leukemia, mouse, success",
                        label: "Provide some key words.",
                        type: "text",
                        maxLength: 300,
                        min: 3,
                        required: true,
                        error: "",
                        example: "genetics, leukemia, mouse, success"
                    }
                    ],
                    example: {
                        // "output": "Understanding CLL biology through mouse models of human genetics.",
                        outputs: [output1, output2],
                        color: "indigo"
                    }
                },
            ]
        };

        // Set prompts from the tool data
        this.prompts = [...this.tool.prompts];
    }

    @observable showAngelmanInfo = false;

    handleInfoClick = (option) => {
        if (option === "Enhance") {
            this.currentOption = "Enhance"; // Set current option to Enhance
        } else if (option === "Sections") {
            this.currentOption = "Sections"; // Set current option to Sections
        } else if (option === "Smart Document") {
            this.currentOption = "Smart Document"
        }
    };

    handleCurrentPrompt = (val) => {
        this.currentPrompt = val
    }

    @computed get isGenerateButtonDisabled() {



        if (this.loading) {
            return true
        }

        return false
    }

    @computed get disabled() {

        if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
            return true
        }


        // this.prompts[this.currentPrompt].prompts[promptIndex].value
        return false
    }

    @computed get isMinLength() {

        if (!this.props.prompt.min) {
            return false
        }
        if (!this.props.prompt.type === "number") {
            return false
        }

        return false
    }

    checkMinimumPrompts = () => {

        let shouldReturn = false

        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            if (prompt.min) {
                if (prompt.value.length < prompt.min) {
                    shouldReturn = true
                    prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
                }
            }
        })

        return shouldReturn
    }


    clearExampleTimeout = []

    onStartUsing = async () => {
        this.loading = false
        this.error = ""
        this.clearExampleTimeout.forEach((item, index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })
        this.currentOption = "Sections"
    }

    onExample = async () => {
        this.loading = true;
        this.error = "";
        this.output = "";
        this.outputs = [];
        this.code = ``;

        // Clear all existing timeouts to avoid duplication or stale data usage
        this.clearExampleTimeout.forEach(clearTimeout);
        this.clearExampleTimeout = [];

        this.currentOption = "Example";

        // Example text filling logic simplified
        let totalLength = 0;
        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            // Reset each prompt value
            prompt.value = "";
            // Simulate typing each character
            for (let char of prompt.example) {
                this.clearExampleTimeout.push(setTimeout(() => {
                    this.prompts[this.currentPrompt].prompts[promptIndex].value += char;
                    // Force a single batch update after all characters are typed
                    if (promptIndex === this.prompts[this.currentPrompt].prompts.length - 1 && char === prompt.example[prompt.example.length - 1]) {
                        this.forceUpdate();
                    }
                }, 7 * totalLength++));
            }
        });

        // Handle the output example
        if (this.prompts[this.currentPrompt].example.output) {
            this.clearExampleTimeout.push(setTimeout(() => {
                this.output = this.prompts[this.currentPrompt].example.output;
                this.loading = false;
                this.currentOption = "Sections";
            }, 7 * totalLength));
        }

        // Handle the code example
        if (this.prompts[this.currentPrompt].example.code) {
            this.clearExampleTimeout.push(setTimeout(() => {
                this.code = this.prompts[this.currentPrompt].example.code;
                this.loading = false;
            }, 7 * totalLength));
        }

        // Handle additional outputs if available
        if (this.prompts[this.currentPrompt].example.outputs) {
            this.outputs = this.prompts[this.currentPrompt].example.outputs;
            this.loading = false;
            this.currentOption = "Sections";
        }
    }

    sanitizeAllPrompts = () => {
        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            if (!prompt.value) {
                return false;
            }
            if (prompt.type === "number") {
                return false;
            }

            prompt.value = prompt.value.trim()

            if (filterBadWords.isProfane(prompt.value)) {
                prompt.error = "Unsafe content , please try different language"
                throw Error("Unsafe content")
            }
        })
    }

    contentFilterFlagged = async (response) => {
        this.error = response.message

        this.date = Date.now() + 5000
        this.countdown.forEach(countdown => {
            if (countdown) {
                countdown.stop()
                countdown.start()
            }
        })
        this.loading = false
    }

    checkOutput = (output) => {
        if (output) {
            output = output.replace(/^\s+|\s+$/g, '')
            // output = output.replace(/\s{2,}/g, ' ')
        }
        return output
    }

    @computed get language() {
        let language = ""
        this.prompts[this.currentPrompt].prompts.forEach(prompt => {
            if (prompt.attr === "language") {
                language = `${prompt.value}`
            }
        })
        return language
    }

    onGenerateClick = async () => {
        console.log("Generate button clicked");

        try {
            this.error = ""
            this.output = ""
            this.code = ``
            this.outputs = []
            this.loading = true

            let checkMinimumPrompts = this.checkMinimumPrompts()
            if (checkMinimumPrompts) {
                this.loading = false
                return false
            }
            // this.sanitizeAllPrompts()

            let postObj = {}

            // Cada prompt corresponde a una pestaña
            this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                postObj[prompt.attr] = prompt.value
            })

            postObj.currentPrompt = this.prompts[this.currentPrompt].title
            if (this.prompts[this.currentPrompt].n) {
                postObj.n = this.prompts[this.currentPrompt].n
            }

            // let content = this.prompts[this.currentPrompt].value
            // let content = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content').value;
            let currentPromptTitle = this.prompts[this.currentPrompt].title;
            let content_doc_type = ""
            let content_additional_req = ""
            let content_title = ""
            let content_context = ""
            let content_k_words = ""

            const apiUrl = `${ResearchPaper.API_BASE_URL}/users/${this.userID}/chats/${this.userID}-14/question`;
            const headers = {
                "Content-Type": "application/json",
                "X-Api-Key": "xxx",
            };

            if (currentPromptTitle === "Layout") {
                content_doc_type = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content' && p.title === 'Document Type').value;
                content_additional_req = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content' && p.title === 'Additional requests').value;
                const question = `Provide a simple text layout for a ${content_doc_type} that includes all the typical sections and elements. Please describe it as if for someone using a basic text editor without formatting capabilities. Consider also these additional instructions '${content_additional_req}'.`
                const system_prompt = `Your guidance should help users structure their writing effectively, facilitating clarity and coherence in their documents.`

                const payload = {
                    question: question,
                    organization_name: ResearchPaper.organizationName,
                    user_name: this.userEmail,
                    system_prompt: system_prompt,
                    application_name: ResearchPaper.applicationName,
                };

                try {
                    const response = await axios.post(apiUrl, payload, { headers });
                    if (response.status === 200 && response.data) {
                        this.output = response.data.Answer;
                        // this.outputs.push(response.data.Answer); // To keep a history of outputs
                    } else {
                        this.error = "Failed to fetch the answer.";
                    }
                } catch (error) {
                    this.error = error.message || "An error occurred while fetching the answer.";
                }
            }

            if (currentPromptTitle === "Title") {
                content_title = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content' && p.title === 'Title').value;
                content_context = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content' && p.title === 'Context').value;
                content_k_words = this.prompts[this.currentPrompt].prompts.find(p => p.attr === 'content' && p.title === 'Key words').value;
                const question1 = `I have drafted a title for my document based on its context and some keywords I want to incorporate. The context of the document is '${content_context}', and the keywords include '${content_k_words}'. The suggested title is '${content_title}'. Could you provide feedback on this title? How well does it capture the essence of the document and incorporate the keywords? Are there any improvements you would suggest to make it more effective or engaging? Do not provide alternative examples.`
                const question2 = `I have drafted a title for my document based on its context and some keywords I want to incorporate. The context of the document is '${content_context}', and the keywords include '${content_k_words}'. The suggested title is '${content_title}'. Provide three title alternatives.`
                const system_prompt = `Ensure each recomendation reflects the content and purpose of the contents provided. Focus on creativity and precision in your suggestions, aiming to capture the reader's interest and convey the essence of the document effectively.`

                const payload1 = {
                    question: question1,
                    organization_name: ResearchPaper.organizationName,
                    user_name: this.userEmail,
                    system_prompt: system_prompt,
                    application_name: ResearchPaper.applicationName,
                };

                try {
                    const response1 = await axios.post(apiUrl, payload1, { headers });
                    if (response1.status === 200 && response1.data) {
                        // this.output = response1.data.Answer;

                        const feedback = `Feedback

${response1.data.Answer}`

                        this.outputs.push(feedback); // To keep a history of outputs
                    } else {
                        this.error = "Failed to fetch the answer.";
                    }
                } catch (error) {
                    this.error = error.message || "An error occurred while fetching the answer.";
                }

                const payload2 = {
                    question: question2,
                    organization_name: ResearchPaper.organizationName,
                    user_name: this.userEmail,
                    system_prompt: system_prompt,
                    application_name: ResearchPaper.applicationName,
                };

                try {
                    const response2 = await axios.post(apiUrl, payload2, { headers });
                    if (response2.status === 200 && response2.data) {
                        // this.output = response2.data.Answer;

                        const alternavites = `Alternatives

${response2.data.Answer}`

                        this.outputs.push(alternavites);
                    } else {
                        this.error = "Failed to fetch the answer.";
                    }
                } catch (error) {
                    this.error = error.message || "An error occurred while fetching the answer.";
                }
            }

            this.date = Date.now() + 10000
            this.countdown.forEach(countdown => {
                if (countdown) {
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        } catch (error) {
            console.log(error)
            this.countdown.forEach(countdown => {
                if (countdown) {
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        }
    }


    handleSelectText = (text) => {
        this.selectedText = text;
        console.log("Updated selected text:", this.selectedText);
    };

    handleUpdateFromTipTapEditor = (newOutput) => {
        this.setState({ tipTapAIText: newOutput });
    };

    handleLoadingChange = (isLoading) => {
        this.setState({ loading: isLoading });
    };

    render() {

        // required for mobx to pick up deeply nested value 
        const currentValue = this.prompts[this.currentPrompt].prompts[0].value

        return (
            <>
                <Helmet>
                    <title>{`${this.tool.title}`}</title>
                </Helmet>
                <HeaderPaper
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={this.tool.Icon}
                    fromColor={this.tool.fromColor}
                    category={this.tool.category}

                    options={[
                        {
                            title: "Sections",
                            Icon: PencilIcon,
                            color: this.props.store.profile.credits ? 'green' : 'red',
                            onClick: () => this.handleInfoClick("Sections")
                        },
                        { title: "Example", color: 'yellow', Icon: InformationCircleIcon, onClick: this.onExample },
                    ]}
                    currentOption={this.currentOption}
                    onInfoClick={() => this.handleInfoClick("Enhance")}
                    onInfoClick2={() => this.handleInfoClick("Smart Document")}
                // image={{ url: Image, alt: "Header Image" }}
                />
                {this.currentOption === "Enhance" ? (
                    <div className={`container mx-auto overflow-hidden md:px-46 md:py-8 lg:py-12 relative`}>
                        <div className="grid grid-cols-6 xl:grid-cols-12 xl:gap-12">
                            <div className="col-span-full xl:col-span-7 z-10 relative">
                                <TextEditor
                                    editorState={this.state.value}
                                    onChange={this.handleEditorChange}
                                    onSelectText={this.handleSelectText}
                                />
                            </div>
                            <div className="col-span-full xl:col-span-5 relative">
                                <OutputTextEditor
                                    title="Enhance"
                                    desc="Modify specific parts of your document for clarity and style while maintaining factual accuracy and avoiding plagiarism."
                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
                                    toColor={this.tool.toColor}
                                    loading={this.loading}
                                    output={this.selectedText}
                                    outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}
                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                                    currentOption={this.currentOption}
                                />
                            </div>
                        </div>
                    </div>

                ) : this.currentOption === "Smart Document" ? (
                    <div className={`container mx-auto overflow-hidden md:px-46 md:py-8 lg:py-12 relative`}>
                        <div className="grid grid-cols-6 xl:grid-cols-12 xl:gap-12">
                            <div className="col-span-full xl:col-span-7 z-10 relative">
                                <TipTapEditor
                                    content={this.state.value.toString('html')}  // Convert state to HTML if necessary
                                    onChange={(newContent) => this.setState({ value: newContent })}
                                    onUpdateOutput={this.handleUpdateFromTipTapEditor}
                                    onLoadingChange={this.handleLoadingChange}
                                    userID={this.props.store.profile._id}
                                    userEmail={this.props.store.profile.email}
                                    organizationName={this.props.store.organizationName}
                                    applicationName={this.props.store.applicationName}
                                    API_BASE_URL={this.props.store.llmApiBaseUrl}
                                />
                            </div>
                            <div className="col-span-full xl:col-span-5 relative">
                                <OutputSmallText
                                    title="AI answer"
                                    // desc="Modify specific parts of your document for clarity and style while maintaining factual accuracy and avoiding plagiarism."
                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
                                    toColor={this.tool.toColor}
                                    loading={this.state.loading}
                                    output={this.state.tipTapAIText}
                                    // outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}
                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                                    currentOption={this.currentOption}
                                />
                            </div>
                        </div>
                    </div>

                ) : (
                    <Body>
                        <Grid>
                            <Col span="7">

                                <EntryTabs
                                    prompts={this.prompts}
                                    currentPrompt={this.currentPrompt}
                                    onChange={this.handleCurrentPrompt}
                                />

                                {this.prompts.map((prompt, index) =>
                                    <EntryPrompt
                                        prompt={prompt}
                                        key={index}
                                        index={index}
                                        disabled={this.disabled}
                                        currentPrompt={this.currentPrompt}
                                    >
                                        {prompt.prompts.map((promptInput, index) =>
                                            <EntryInput
                                                prompt={promptInput}
                                                key={index}
                                                language={this.language}
                                                index={index}
                                                disabled={this.disabled}
                                            />)}


                                        <div className="md:flex">
                                            <Countdown
                                                ref={countdown => this.countdown[index] = countdown}
                                                date={this.date}
                                                renderer={props =>
                                                    <Button
                                                        title={props.total ? `Timeout ${props.total / 1000} secs` : "Perform Request"}
                                                        disabled={props.total || this.isGenerateButtonDisabled}
                                                        Icon={props.total ? ClockIcon : currentValue ? DuplicateIcon : PencilIcon}
                                                        onClick={this.onGenerateClick}
                                                    />}
                                            />
                                            <EntryN
                                                prompts={this.prompts}
                                                currentPrompt={this.currentPrompt}
                                            />
                                        </div>


                                        {this.error && <div className="mt-4"><label
                                            className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>
                                            {this.error}
                                        </label></div>}

                                    </EntryPrompt>
                                )}

                            </Col>
                            <Col span="5">
                                <ResearchPaperSectionsOutput
                                    title={this.tool.output.title}
                                    desc={this.tool.output.desc}

                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
                                    toColor={this.tool.toColor}

                                    loading={this.loading}
                                    output={this.output}
                                    outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}

                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                                />
                            </Col>
                        </Grid>
                    </Body>
                )}
            </>
        )
    }
}


export default ResearchPaper