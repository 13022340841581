import React from 'react';

class VedMedKaryotyping extends React.Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <iframe
          src="https://www.vedmed.eu/karyotyping/"
          title="External Content"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        >
          Your browser does not support iframes.
        </iframe>
      </div>
    );
  }
}

export default VedMedKaryotyping;
