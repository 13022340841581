import './styles.scss'

import {
  BubbleMenu,
  EditorContent,
  FloatingMenu,
  useEditor,
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'
import axios from 'axios';
import GetSelectedText from './getSelectedText';
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Heading from '@tiptap/extension-heading'
import Image from '@tiptap/extension-image'
import Typography from '@tiptap/extension-typography'



export default function TipTapEditor({ onUpdateOutput, onLoadingChange, userID, userEmail, organizationName, applicationName, API_BASE_URL }) {

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      GetSelectedText,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      Image.configure({
        inline: true,
      }),
      Typography,
    ],
    content: `
        <h1><strong>This is a sample Document Layout for a Research Paper</strong></h1>
    
        <p>Author: <em>Author Name</em></p>
        <p>Institution: <em>University Name</em></p>
        <p>Date: <em>Submission Date</em></p>
        <p></p>
    
        <h2><strong>Abstract</strong></h2>
        <p>This section should provide a brief summary of the research question, methodology, results, and conclusions. It should be succinct and informative, designed to give the reader a quick overview of the paper.</p>
    
        <h2><strong>Introduction</strong></h2>
        <p>This section introduces the background of the research topic, outlines the research problem, and states the objectives of the study. It should also provide a brief overview of the paper's structure.</p>
    
        <h2><strong>Methodology</strong></h2>
        <p>This part describes the research design, data sources, analytical techniques, and procedures used in the study. It should be detailed enough for the study to be reproducible.</p>
    
        <h2><strong>Results</strong></h2>
        <p>Here, present the findings of the study without interpretation. Use figures, tables, and graphs to support the data collected.</p>
    
        <h2><strong>Discussion</strong></h2>
        <p>Discuss the implications of the results and how they fit into the context of existing research. Mention any limitations of the study and propose areas for future research.</p>
    
        <h2><strong>Conclusion</strong></h2>
        <p>Summarize the findings, restate the significance of the research, and highlight any potential applications of the work. This section should reinforce why the research matters.</p>
    
        <h2><strong>References</strong></h2>
        <p>List all the scholarly works cited in the document. Ensure each reference is formatted according to the specified citation style (e.g., APA, MLA, Chicago).</p>
    `,
  })

  const systemPromptTrim = `
Smart Trim Task:
Condense the provided text to a shorter version while maintaining all critical facts and core meanings. Ensure the trimmed version is concise, clear, and retains the essential elements of the original text.
`;

  const systemPromptRephrase = `Your primary task is to rephrase sentences or paragraphs to improve readability or adjust the style of writing while retaining the original intent, meaning, and significant details. Follow these specific instructions:
	
1. Rephrase for clarity and style: Rewrite sentences or paragraphs using clear, straightforward language. Ensure that the rephrased version maintains the same factual content and meaning as the original.

2. Maintain factual accuracy: Strictly adhere to the information presented in the source material without introducing interpretations or opinions.

3. Avoid plagiarism: Ensure that your outputs are sufficiently transformed from the original text but still reflect the original points accurately.

4. Ensure quality control: Review your output for coherence, fluency, and alignment with the source’s tone where appropriate.

Apply these guidelines consistently to produce reliable and useful outputs for users seeking rephrased content that preserves the accuracy and intent of the original text.
`;

  const systemPromptKeywords = `
Keyword Extraction Task:
Identify and extract the most relevant keywords from the provided text, focusing on terms that capture the core subjects and important themes.
`;

  const [loading, setLoading] = React.useState(false);

  async function askAI(instruction) {
    let text = editor.commands.getSelectedText();
    if (!text) {
      console.log("No text selected");
      return;  // Exit if no text is selected
    }
    setLoading(true);
    onLoadingChange(true);

    if (instruction) {
      text = `${instruction}: "${text}"`
    }

    const apiUrl = `${API_BASE_URL}/users/${userID}/chats/${userID}-11/question`;
    const payload = {
      question: text,
      organization_name: organizationName,
      user_name: userEmail,
      system_prompt: systemPromptKeywords,
      application_name: applicationName,
    };
    const headers = {
      "Content-Type": "application/json",
      "X-Api-Key": "xxx",
    };

    try {
      const response = await axios.post(apiUrl, payload, { headers });
      if (response.status === 200 && response.data) {
        onUpdateOutput(response.data.Answer);
      } else {
        console.error("Failed to extract keywords: ", response.status);
        onUpdateOutput("Failed to extract keywords.");
      }
    } catch (error) {
      console.error("Error during keyword extraction operation: ", error);
      onUpdateOutput("Error during keyword extraction operation.");
    }
    setLoading(false);
    onLoadingChange(false);
  }


  return (
    <>
      <div className="editor-container">
        <MenuBar editor={editor} />
        {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
          {/* <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            Bold
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            Italic
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            Strike
          </button> */}
          <div className='ai-text'>AI:  </div>
          <button onClick={askAI}
            disabled={loading || !editor.getHTML()}>
            Ask
          </button>
          <button onClick={() => askAI('Rephrase the following text for improved clarity and style, while maintaining the original meaning and factual accuracy')}
            disabled={loading || !editor.getHTML()}>
            Rephrase
          </button>
          <button onClick={() => askAI('Simplify the following text to make it easier to understand, while preserving the essential information')}
            disabled={loading || !editor.getHTML()}>
            Simplify
          </button>
          <button onClick={() => askAI('Correct any spelling and grammar mistakes in the following text, ensuring it reads smoothly and professionally')}
            disabled={loading || !editor.getHTML()}>
            Spelling & Grammar
          </button>
          <button onClick={() => askAI('Condense the following text to make it more concise, while retaining the key points')}
            disabled={loading || !editor.getHTML()}>
            Shorter
          </button>
          <button onClick={() => askAI('Expand the following text to provide more detail and context, while keeping the original message and accuracy')}
            disabled={loading || !editor.getHTML()}>
            Longer
          </button>
          <button onClick={() => askAI('Add emojis to the following text to enhance its emotional expression and appeal, without altering the original meaning')}
            disabled={loading || !editor.getHTML()}>
            Emojify
          </button>
          <button onClick={() => askAI('Complete the following sentence in a way that maintains its initial intent and style')}
            disabled={loading || !editor.getHTML()}>
            Complete Sentence
          </button>
          <button
            onClick={() => askAI('Extract keywords from the following text')}
            disabled={loading || !editor.getHTML()}>
            Keywords
          </button>
        </BubbleMenu>}

        {/* {editor && <FloatingMenu className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            H1
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            H2
          </button>
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          >
            Bullet List
          </button>
        </FloatingMenu>} */}

        <EditorContent editor={editor} className="editor-content" />
      </div>
    </>
  )
}

const MenuBar = ({ editor }) => {
  //   const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  const buttonActive = "mb-1 mt-1 bg-black hover:bg-yellow-500 text-white font-bold py-0 px-2 rounded-full";
  const buttonInactive = "mb-1 mt-1 bg-white hover:bg-yellow-500 text-black py-0 px-2 rounded-full";
  const buttonStatic = "mb-1 mt-1 bg-white hover:bg-yellow-500 text-black font-bold py-0 px-2 rounded-full";

  return (
    <>
      <div className='MenuBar'>
        <div className="button-group">
          {/* Text Formatting */}
          <button className={editor.isActive('bold') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleBold().run()}>Bold</button>
          <button className={editor.isActive('italic') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleItalic().run()}>Italic</button>
          <button className={editor.isActive('strike') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleStrike().run()}>Strike</button>
          <button className={editor.isActive('code') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleCode().run()}>Code</button>
          <div className="separator"></div>
          {/* Text Alignment */}
          <button className={editor.isActive({ textAlign: 'left' }) ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().setTextAlign('left').run()}>Left</button>
          <button className={editor.isActive({ textAlign: 'center' }) ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().setTextAlign('center').run()}>Center</button>
          <button className={editor.isActive({ textAlign: 'right' }) ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().setTextAlign('right').run()}>Right</button>
          <button className={editor.isActive({ textAlign: 'justify' }) ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().setTextAlign('justify').run()}>Justify</button>
          <div className="separator"></div>
          <button className={buttonStatic} onClick={() => editor.chain().focus().undo().run()}>Undo</button>
          <button className={buttonStatic} onClick={() => editor.chain().focus().redo().run()}>Redo</button>
        </div>
        {/* Headings */}
        {/* <div className="button-group">
        {[1, 2, 3, 4, 5, 6].map(level => (
          <button className={editor.isActive('heading', { level }) ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleHeading({ level }).run()}>H{level}</button>
        ))}
      </div> */}

        <div className="button-group">
          {/* Lists and Blocks */}
          <button className={editor.isActive('bulletList') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleBulletList().run()}>Bullet List</button>
          <button className={editor.isActive('codeBlock') ? buttonActive : buttonInactive} onClick={() => editor.chain().focus().toggleCodeBlock().run()}>Code Block</button>
          <div className="separator"></div>
          {/* Document Operations */}
          <button className={buttonStatic} onClick={() => editor.chain().focus().setHorizontalRule().run()}>Horizontal Rule</button>
          <button className={buttonStatic} onClick={() => editor.chain().focus().setHardBreak().run()}>Hard Break</button>

        </div>


        {/* <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        // className={editor.isActive('bold') ? 'is-active' : ''}
        // className={`${buttonClass} ${editor.isActive('bold') ? 'is-active' : ''}`}
        className={`${editor.isActive('bold') ? buttonActive : buttonInactive}`}
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        // className={editor.isActive('italic') ? 'is-active' : ''}
        className={`${editor.isActive('italic') ? buttonActive : buttonInactive}`}
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        // className={editor.isActive('strike') ? 'is-active' : ''}
        className={`${editor.isActive('strike') ? buttonActive : buttonInactive}`}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        // className={editor.isActive('code') ? 'is-active' : ''}
        className={`${editor.isActive('code') ? buttonActive : buttonInactive}`}
      >
        code
      </button>
      {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}
      className={buttonBaseClass}>
        clear marks
      </button>
      <button onClick={() => editor.chain().focus().clearNodes().run()}
      className={buttonBaseClass}>
        clear nodes
      </button> */}
        {/* <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        // className={editor.isActive('paragraph') ? 'is-active' : ''}
        className={`${editor.isActive('paragraph') ? buttonActive : buttonInactive}`}
      >
        paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        // className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 1 }) ? buttonActive : buttonInactive}
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        // className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 2 }) ? buttonActive : buttonInactive}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        // className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 3 }) ? buttonActive : buttonInactive}
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        // className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 4 }) ? buttonActive : buttonInactive}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        // className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 5 }) ? buttonActive : buttonInactive}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        // className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        className={editor.isActive('heading', { level: 6 }) ? buttonActive : buttonInactive}
      >
        h6
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        // className={editor.isActive('bulletList') ? 'is-active' : ''}
        className={`${editor.isActive('bulletList') ? buttonActive : buttonInactive}`}
      >
        bullet list
      </button> */}
        {/* <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        // className={editor.isActive('orderedList') ? 'is-active' : ''}
        className={`${editor.isActive('orderedList') ? buttonActive : buttonInactive}`}
      >
        ordered list
      </button> */}
        {/* <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        // className={editor.isActive('codeBlock') ? 'is-active' : ''}
        className={`${editor.isActive('codeBlock') ? buttonActive : buttonInactive}`}
      >
        code block
      </button> */}
        {/* <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        // className={editor.isActive('blockquote') ? 'is-active' : ''}
        className={`${editor.isActive('blockquote') ? buttonActive : buttonInactive}`}
      >
        blockquote
      </button> */}

        {/* <button
        onClick={() => editor.chain().focus().setColor('#958DF1').run()}
        className={editor.isActive('textStyle', { color: '#958DF1' }) ? buttonActive : buttonInactive}
      >
        purple
      </button> */}
        {/* <button onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? buttonActive : buttonInactive}>
        highlight
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? buttonActive : buttonInactive}>
        left
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? buttonActive : buttonInactive}>
        center
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? buttonActive : buttonInactive}>
        right
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('justify').run()} className={editor.isActive({ textAlign: 'justify' }) ? buttonActive : buttonInactive}>
        justify
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}
      className={`${buttonStatic}`}>
        horizontal rule
      </button>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}
      className={`${buttonStatic}`}>
        hard break
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
        className={`${buttonStatic}`}
      >
        undo
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
        className={`${buttonStatic}`}
      >
        redo
      </button> */}
      </div>
    </>

  )
}