import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import noImage from '../../tools/auto/no-image.jpeg';
import Header from '../Header';
import Body, { Grid, Col } from '../Body'
import { Helmet } from "react-helmet";
import QuestionAugmentation from './QuestionAugmentation'
import QuestionManager from './QuestionManager'
import {
    PencilIcon,
    ClockIcon,
    InformationCircleIcon,
    DuplicateIcon,
    ViewListIcon,
    PlusIcon,
    DocumentAddIcon,
} from '@heroicons/react/outline'
import { inject } from 'mobx-react'
import Modal from '../Modal';
import YouTubeRangeSelector from '../YouYubeSelector/YouTubeSelector.jsx';
import ErrorBoundary from './ErrorBoundary'
import _ from 'lodash';

const API_BASE_URL = 'https://www.ricocorp.xyz';

@inject('store')
class AddQuestion extends Component {
    constructor(props) {
        super(props);

        const { organization, _id } = this.props.store.profile;

        this.state = {
            formData: {
                organizationName: organization,
                licenseType: 'permiso_b',
                externalUserId: _id,
                question: '',
                answerA: '',
                answerB: '',
                answerC: '',
                answerD: '',
                correctAnswer: '',
                explanation: '',
                topic: '',
                tags: '',
                image: null,
                level: 3,
            },
            topicsList: [],
            resetKey: Date.now(),  // Unique key for the file input and correctAnswer to force re-render
            loading: false,
            message: '',
            previewImage: noImage,
            currentOption: "Create",
            showModal: false,
            selectedVideoRanges: [],
            grammarResults: null,
            showGrammarModal: false,
            isGrammarChecking: false,
        };
        this.handleVideoRangesChange = this.handleVideoRangesChange.bind(this);
    }


    handleGrammarCheck = async () => {
        this.setState({ isGrammarChecking: true });
        const fieldsToCheck = ['question', 'answerA', 'answerB', 'answerC', 'answerD', 'explanation'];
        const results = {};

        for (const field of fieldsToCheck) {
            if (this.state.formData[field]) {
                try {
                    const result = await this.askAI(`Comprueba la gramática, puntuación, mayúsculas/minúsculas, utilizando un estilo formal. Devuelve el siguiente texto corregido, sin comillas, símbolos adicionales ni explicaciones: "${this.state.formData[field]}"`);
                    results[field] = {
                        original: this.state.formData[field],
                        corrected: result
                    };
                } catch (error) {
                    console.error(`Error checking ${field}:`, error);
                    results[field] = {
                        original: this.state.formData[field],
                        corrected: `Error checking ${field}`
                    };
                }
            }
        }

        this.setState({
            grammarResults: results,
            showGrammarModal: true,
            isGrammarChecking: false
        });
    };

    askAI = async (instruction) => {
        const system_prompt = `Eres un asistente experto en gramática y puntuación. Tu tarea es corregir errores gramaticales y de puntuación en los textos que se te proporcionen, siguiendo estas pautas:

1. Mantén el contenido y significado original del texto.
2. Corrige errores ortográficos, gramaticales y de puntuación.
3. Mejora la estructura de las oraciones si es necesario, pero sin cambiar el estilo del autor.
4. No agregues ni elimines información.
5. Respeta el tono y registro del texto original.

Importante: Responde ÚNICAMENTE con el texto corregido, sin agregar explicaciones, comentarios o cualquier otro texto adicional.

Si el texto no requiere correcciones, devuelve el texto original sin cambios.`
        const apiUrl = `${API_BASE_URL}/users/${this.props.store.profile._id}/chats/${this.props.store.profile._id}-question_editor/question`;
        const payload = {
            question: instruction,
            organization_name: this.state.formData.organizationName,
            user_name: this.props.store.profile.email,
            system_prompt: system_prompt,
            application_name: "Vates",
        };
        const headers = {
            "Content-Type": "application/json",
            "X-Api-Key": "xxx",
        };

        try {
            const response = await axios.post(apiUrl, payload, { headers });
            if (response.status === 200 && response.data) {
                return response.data.Answer;
            } else {
                throw new Error("Failed to get AI response");
            }
        } catch (error) {
            console.error("Error during AI operation: ", error);
            throw error;
        }
    };

    handleVideoRangesChange = (ranges) => {
        this.setState({ selectedVideoRanges: ranges });
    };

    async componentDidMount() {
        try {
            const response = await axios.get(`${API_BASE_URL}/topics/DGT Permiso B`);
            if (response.status === 200) {
                this.setState({ topicsList: response.data.topics });
            }
        } catch (error) {
            console.error('Failed to fetch topics:', error);
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleTopicChange = (event) => {
        const options = event.target.options;
        const selectedTopics = [];
        for (const option of options) {
            if (option.selected) {
                selectedTopics.push(parseInt(option.value, 10));
            }
        }
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                topics: selectedTopics
            }
        }));
    };

    handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    previewImage: reader.result
                });
            };
            reader.readAsDataURL(file);
            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    image: file
                }
            }));
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    image: null // Reset to no image if the user clears the selection
                },
                previewImage: noImage // Set back to default when no image is selected
            });
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        const { formData, topicsList } = this.state;

        // Validate required fields
        const requiredFields_pretty = ['"Pregunta"', '"Respuesta A"', '"Respuesta B"', '"Respuesta Correcta"', '"Temas" (al menos uno)', '"Nivel de dificultad"'];
        const requiredFields = ['question', 'answerA', 'answerB', 'correctAnswer', 'level'];
        const missingFields = requiredFields.filter(field => !formData[field]);

        console.log(missingFields)
        if (missingFields.length > 0 || formData.topics.length === 0) {
            const missingFieldsMessage = `Por favor, asegurese de que rellena como mínimo: ${requiredFields_pretty.join(', ')}.`;
            this.setState({ showModal: true, message: missingFieldsMessage, loading: false });
            return;
        }

        // Convert empty strings to null for optional fields
        const convertEmptyToNull = (value) => value === '' ? null : value;

        // Prepare JSON data for the request
        const jsonData = {
            organization_name: formData.organizationName,
            license_type: formData.licenseType,
            external_user_id: formData.externalUserId,
            question: formData.question,
            answerA: formData.answerA,
            answerB: formData.answerB,
            answerC: convertEmptyToNull(formData.answerC),
            answerD: convertEmptyToNull(formData.answerD),
            correct_answer: formData.correctAnswer,
            explanation: formData.explanation,
            topic_ids: formData.topics,  // Send array of topic IDs
            tags: convertEmptyToNull(formData.tags),
            image_data: formData.image ? await this.convertToBase64(formData.image) : null,
            level: formData.level,
            video_ranges: this.state.selectedVideoRanges,
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/add_question`, jsonData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                window.alert('Pregunta guardada con éxito'); // Alert user
                this.resetForm(); // Reset form after user acknowledges the alert
            }
        } catch (error) {
            console.error('Failed to add question:', error);
            // this.setState({ showModal: true, message: 'Failed to add question. Please check the console for more information.' });
            this.setState({ showModal: true, message: 'No ha sido posible guardar la pregunta. Compruebe que ha rellenado todo los campos requeridos e intentelo de nuevo. En caso de que el error persista comuniquelo a su administrador.' });
        }

        this.setState({ loading: false });
    };

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                let base64String = reader.result;
                const base64WithoutPrefix = base64String.split(',')[1]; // Remove the prefix part
                resolve(base64WithoutPrefix);
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    resetForm = () => {
        const { organization, _id } = this.props.store.profile;
        this.setState({
            formData: {
                organizationName: organization,
                licenseType: 'permiso_b',
                externalUserId: _id,
                question: '',
                answerA: '',
                answerB: '',
                answerC: '',
                answerD: '',
                correctAnswer: '',
                explanation: '',
                topics: [],
                tags: '',
                image: null,
                level: 3,
            },
            resetKey: Date.now(),
            previewImage: noImage,
            message: '',
            selectedVideoRanges: [],
        });
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     // Only update if the selectedVideoRanges have changed
    //     return !_.isEqual(this.state.selectedVideoRanges, nextState.selectedVideoRanges);
    //   }

    handleCloseModal = () => {
        this.setState({ showModal: false, message: '' });
    };

    renderAnswerPreview = (answer, index) => {
        const correctStyle = { backgroundColor: '#d4edda', color: '#155724' }; // Bootstrap's .bg-success
        const incorrectStyle = { backgroundColor: '#f8d7da', color: '#721c24' }; // Bootstrap's .bg-danger
        const style = this.state.formData.correctAnswer === answer ? correctStyle : incorrectStyle;

        return (
            <p key={index} style={style}>
                {this.state.formData[`answer${answer}`]}
            </p>
        );
    };

    handleHeaderClick = (option) => {
        this.setState({ currentOption: option });
    };


    handleCorrectedTextChange = (field, value) => {
        this.setState(prevState => ({
            grammarResults: {
                ...prevState.grammarResults,
                [field]: {
                    ...prevState.grammarResults[field],
                    corrected: value
                }
            }
        }));
    };

    applyGrammarChanges = () => {
        const { grammarResults, formData } = this.state;
        const updatedFormData = { ...formData };

        Object.entries(grammarResults).forEach(([field, { corrected }]) => {
            updatedFormData[field] = corrected;
        });

        this.setState({ formData: updatedFormData, showGrammarModal: false });
    };

    render() {
        const { formData, loading, message, previewImage, resetKey, currentOption, showModal } = this.state;
        return (
            <>
                <Helmet>
                    <title>{"Administración"}</title>
                </Helmet>
                <Header
                    title={"Admin"}
                    desc={"Admin module."}
                    Icon={ViewListIcon}
                    fromColor={"blue-500"}
                    category={"Admin"}

                    options={[
                        {
                            title: "Creación Preguntas",
                            Icon: PlusIcon,
                            color: 'green',
                            onClick: () => this.handleHeaderClick("Create")
                        },
                        { title: "Aumentar con IA / Borrar", color: 'yellow', Icon: PencilIcon, onClick: () => this.handleHeaderClick("Augment") },
                        { title: "Editar", color: 'yellow', Icon: PencilIcon, onClick: () => this.handleHeaderClick("Edit") },
                    ]}
                    currentOption={currentOption}
                />
                <Body>
                    {currentOption === "Augment" ? (
                        <QuestionAugmentation></QuestionAugmentation>
                    ) : currentOption === "Edit" ? (
                        <QuestionManager
                            organizationName={this.state.formData.organizationName}
                            userId={this.props.store.profile._id}
                        />
                      ) : (
                        <Grid>
                            {/* <div className="max-w-4xl mx-auto p-4 flex flex-row space-x-4"> */}
                            <Col span="6">
                                <div className="relative flex-1 min-w-[40%]">
                                    {/* <div className="relative mb-12"> */}
                                    <div className={`absolute inset-0 bg-gradient-to-r from-gray-500 to-gray-500 shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`} style={{ zIndex: -1 }}></div>
                                    <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-4 flex flex-col justify-between items-center">
                                        <div className="text-xl font-bold">Nueva Pregunta</div>
                                    </div>
                                    <form className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-4 flex flex-col space-y-4">
                                        {this.renderLicenseTypeDropdown()}
                                        {this.renderInput("question", "Pregunta", "text", true)}
                                        {this.renderInput("answerA", "Respuesta A", "text", true)}
                                        {this.renderInput("answerB", "Respuesta B", "text", true)}
                                        {this.renderInput("answerC", "Respuesta C", "text", false)}
                                        {this.renderInput("answerD", "Respuesta D", "text", false)}
                                        {this.renderCorrectAnswerDropdown()}
                                        {this.renderInput("explanation", "Explicación", "text", true)}
                                        <div>
                                            <label className="block text-sm font-bold mb-2" htmlFor="videoRanges">Vídeos:</label>
                                            <ErrorBoundary>
                                                <YouTubeRangeSelector
                                                    onRangesChange={this.handleVideoRangesChange}
                                                    key={`youtube-selector-${this.state.resetKey}`}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        {this.renderTopicsDropdown()}
                                        {this.renderInput("tags", "Tags", "text", false)}
                                        {/* {this.renderInput("level", "Nivel de dificultad (1=bajo)", "number", true)} */}
                                        {this.renderLevelDropdown()}
                                        <div>
                                            <label className="block text-sm font-bold mb-2" htmlFor="image">Imagen:</label>
                                            <input
                                                type="file"
                                                name="image"
                                                onChange={this.handleImageChange}
                                                className="form-input mt-1 block w-full"
                                                key={resetKey}  // Key to force re-render
                                            />
                                        </div>
                                    </form>
                                    {message && <p className="mt-4 text-green-500">{message}</p>}
                                </div>
                            </Col>
                            <Col span="6">
                                <div className="relative mb-12">
                                    <div className={`absolute inset-0 bg-gradient-to-r from-blue-500 to-red-300 shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`} style={{ zIndex: -1 }}></div>
                                    <div className="flex-1 bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-4">
                                        {/* Ensure this div acts as a flex container to center the image */}
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {previewImage && <img src={previewImage} alt="Preview" className="max-w-full h-auto mb-4" />}
                                        </div>
                                        <div className="space-y-2">
                                            <p><strong>Pregunta:</strong> {formData.question}</p>
                                            {['A', 'B', 'C', 'D'].map(answer => this.renderAnswerPreview(answer, answer))}
                                            <p><strong>Explicación:</strong> {formData.explanation}</p>
                                            <p><strong>Rangos de Videos seleccionados:</strong></p>
                                            <ul>
                                                {this.state.selectedVideoRanges.map((range, index) => (
                                                    <li key={index}>
                                                        Video ID: {range.videoId}, Inicio: {range.startTime}s, Final: {range.endTime}s
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-4 mt-6">
                                    <button
                                        onClick={this.handleSubmit}
                                        disabled={loading}
                                        className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-150 ${loading
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                : 'bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                                            }`}
                                    >
                                        {loading ? 'Guardando...' : 'Guardar Pregunta'}
                                    </button>
                                    <button
                                        onClick={this.handleGrammarCheck}
                                        disabled={this.state.isGrammarChecking}
                                        className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-150 ${this.state.isGrammarChecking
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                : 'bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50'
                                            }`}
                                    >
                                        {this.state.isGrammarChecking ? 'Revisando...' : 'Revisar Gramática'}
                                    </button>
                                    <button
                                        onClick={this.resetForm}
                                        className="px-4 py-2 rounded-md text-sm font-medium text-red-600 bg-white border border-red-600 hover:bg-red-50 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                                    >
                                        Resetear Formulario
                                    </button>
                                </div>
                            </Col>
                        </Grid>
                    )}
                </Body>
                <Modal show={showModal} handleClose={this.handleCloseModal} title="Atención">
                    {message}
                </Modal>
                <Modal
                    show={this.state.showGrammarModal}
                    handleClose={() => this.setState({ showGrammarModal: false })}
                    title="Resultados de Revisión Gramatical"
                >
                    <div className="max-h-[60vh] overflow-y-auto pr-2">
                        {this.state.grammarResults && Object.entries(this.state.grammarResults).map(([field, { original, corrected }]) => (
                            <div key={field} className="mb-4">
                                <h3 className="font-bold">{field}:</h3>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <p className="text-sm text-gray-600">Original:</p>
                                        <p className="bg-gray-100 p-2 rounded">{original}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-600">Revisado:</p>
                                        <textarea
                                            value={corrected}
                                            onChange={(e) => this.handleCorrectedTextChange(field, e.target.value)}
                                            className="w-full p-2 border rounded"
                                            rows="3"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={this.applyGrammarChanges}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Aplicar Cambios
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    renderInput = (name, placeholder, type, required) => (
        <div style={styles.inputGroup}>
            <label htmlFor={name} style={styles.label}>{placeholder}</label>
            <input
                id={name}
                type={type}
                name={name}
                value={this.state.formData[name]}
                onChange={this.handleInputChange}
                placeholder={placeholder}
                required={required}
                style={styles.input}
                spellCheck="true"
            />
        </div>
    );

    renderTopicsDropdown = () => (
        <div style={styles.inputGroup}>
            <label htmlFor="topics" style={styles.label}>Temas:</label>
            <p style={styles.note}>Mantenga pulsado <strong>Ctrl</strong> para seleccionar varios temas.</p>
            <select
                id="topics"
                name="topics"
                multiple
                value={this.state.formData.topics}
                onChange={this.handleTopicChange}
                style={styles.input}
                required
            >
                {this.state.topicsList.map(topic => (
                    <option key={topic.TopicID} value={topic.TopicID}>
                        {topic.TopicNumber}. {topic.TopicName}
                    </option>
                ))}
            </select>
        </div>
    );

    // renderLicenseTypeDropdown = () => (
    //     <div style={styles.inputGroup}>
    //         <label htmlFor="licenseType" style={styles.label}>Tipo Permiso:</label>
    //         <select
    //             id="licenseType"
    //             name="licenseType"
    //             value={this.state.formData.licenseType}
    //             onChange={this.handleInputChange}
    //             style={styles.input}
    //             readOnly
    //         >
    //             <option value="">Seleccione tipo de Permiso</option>
    //             {/* <option value="permiso_a1a2">A1/A2</option> */}
    //             <option value="permiso_b">B</option>
    //         </select>
    //     </div>
    // );

    renderLicenseTypeDropdown = () => (
        <div style={styles.inputGroup}>
            <label htmlFor="licenseType" style={styles.label}>Permiso:</label>
            <input
                id="licenseType"
                name="licenseType"
                value="B"
                readOnly
                style={styles.input}
            />
        </div>
    );

    renderCorrectAnswerDropdown = () => (
        <div style={styles.inputGroup}>
            <label htmlFor="correctAnswer" style={styles.label}>Respuesta Correcta:</label>
            <select
                id="correctAnswer"
                name="correctAnswer"
                value={this.state.formData.correctAnswer}
                onChange={this.handleInputChange}
                style={styles.input}
                required
                key={this.state.resetKey}  // Use key to force re-render
            >
                <option value="">Seleccione respuesta</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
            </select>
        </div>
    );

    renderLevelDropdown = () => (
        <div style={styles.inputGroup}>
            <label htmlFor="level" style={styles.label}>Nivel de dificultad:</label>
            <select
                id="level"
                name="level"
                value={this.state.formData.level}
                onChange={this.handleInputChange}
                style={styles.input}
                required
            >
                <option value={1}>1 (Verdadero/Falso)</option>
                <option value={2}>2 (Rellenar huecos)</option>
                <option value={3}>3 (Respuesta múltiple fácil)</option>
                <option value={4}>4 (Respuesta múltiple TNT)</option>
                <option value={5}>5 (Preguntas práctica)</option>
                <option value={6}>6 (Simulación exámen)</option>
            </select>
        </div>
    );
}

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputGroup: {
        marginBottom: '10px',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
        display: 'block',
    },
    input: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px', // Add margin top to separate from inputs
    },
    message: {
        color: 'green',
        marginTop: '10px',
    },
    note: {
        fontSize: '0.9em',
        color: '#555',
        marginBottom: '8px',
    }
};

export default AddQuestion;
