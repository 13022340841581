import { Extension } from '@tiptap/core'

const GetSelectedText = Extension.create({
  name: 'getSelectedText',

  addCommands() {
    return {
      getSelectedText: () => ({ editor }) => {
        const { from, to, empty } = editor.state.selection;
        if (empty) {
          return '';
        }
        return editor.state.doc.textBetween(from, to, ' ');
      }
    };
  }
});

export default GetSelectedText;
