import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Documentación DGT (experimental)",
	desc: "Tu Inteligencia Artificial experta en el permiso B, con acceso a documentación oficial de la DGT.",
	category: "Instructor IA",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['all', 'auto'],
	
	fromColor: "yellow-400",
	toColor: "black",

	to: "/ai/dgt/permisob",
	api: "/ai/dgt/permisob",

	output: {
		title: "Instructor IA en el permiso B",
		desc: "La IA puede cometer errores. Considera verificar la información importante.",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Que desea consultar?",
		desc: "Asistente de IA experto en la normativa oficial de la DGT al que puedes solicitar aclaraciones referentes a la teoría del permitso B.",
		// n: 1,
		prompts: [{ 
				title: "Información de referencia", 
				attr: "content",  
				value: "", 
				placeholder: "A un vehículo prioritario que circula en servicio de urgencia se le facilitará el paso...", 
				label: "Información que la IA contrastará con la normativa oficial de la DGT.",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Un peatón pretende cruzar por un paso de peatones debidamente señalizado, ¿cuándo debe penetrar en la calzada?\n\na) Cuando el peatón lo decida, puesto que dispone de la preferencia de paso.\n\nb) Cuando la distancia y velocidad de los vehículos permitan hacerlo con seguridad.\n\nc) Solamente cuando no haya vehículos cerca.",
			},
			{ 
				title: "Cual es su duda?", 
				attr: "question",  
				value: "", 
				placeholder: "Razona la respuesta correcta.", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Razona la respuesta correcta.",
			},
		],
		example: {
			output: "REFERENCIAS \n1. 'Cruzar por los pasos de peatones' cuando esté en verde la señal correspondiente.\n2. 'Esperar en la acera para cruzar. No en la carretera' y 'Mirar siempre antes de cruzar'.\n\nRESPUESTA\nLa respuesta correcta es la b). Los peatones deben cruzar por los pasos de peatones señalizados, pero solo cuando la distancia y velocidad de los vehículos permitan hacerlo con seguridad, aun teniendo preferencia de paso. [1,2] No deben penetrar en la calzada por el simple hecho de tener preferencia, sino que deben comprobar primero que pueden cruzar sin peligro. [2] Solamente penetrar en la calzada cuando no haya vehículos cerca no sería suficiente, ya que los conductores pueden no percibir a tiempo al peatón o no poder detenerse a tiempo, por lo que la opción a) no es correcta.",
			color: "blue",
		}
	}]
		
}

export default obj

