import InfinitePractice from '../../Components/InfinitePractice';
import {
	ViewListIcon,
} from '@heroicons/react/solid'


const infinitePractice = {
    title: "Práctica Infinita", // Display name of the tool
    desc: "Description of what New Tool does.", // A brief description
    category: "Estudio",
	to: "/auto/infinite-practice",
	api: "/ai/auto/infinite-practice",
    Icon: ViewListIcon, // Define your icon component or import it
    desc: "Optimiza tu aprendizaje con técnicas basadas en la ciencia: repetición espaciada y recuperación activa para una retención duradera y eficiente del conocimiento.",
    fromColor: "yellow-400",
    toColor: "black",
    component: InfinitePractice, // The React component that implements the tool
  };
  
  export default infinitePractice;
  