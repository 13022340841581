import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

const PracticeSetup = ({ onSetupComplete }) => {
    const [licenseType, setLicenseType] = useState('permiso_b');
    const [pomodoroTime, setPomodoroTime] = useState(25); // Default 25 minutes
    const [restingTime, setRestingTime] = useState(5); // Default 5 minutes

    const handleStartPractice = () => {
        onSetupComplete({ licenseType, pomodoroTime, restingTime });
    };

    return (
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col items-center justify-center">
            <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 p-8 m-4 w-full md:w-1/2">
                <h2 className="text-3xl font-semibold mb-6 text-center">Sesión de estudio</h2>
                {/* <div className="mb-6">
                    <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="licenseType">
                        Tipo de Licencia
                    </label>
                    <select
                        id="licenseType"
                        name="licenseType"
                        value={licenseType}
                        onChange={(e) => setLicenseType(e.target.value)}
                        className="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    >
                        <option value="permiso_b">Permiso B</option>
                        <option value="permiso_a1">Permiso A1</option>
                        <option value="permiso_a2">Permiso A2</option>
                    </select>
                </div> */}
                <div className="mb-6">
                    <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="pomodoroTime">
                        Tiempo de Trabajo
                    </label>
                    <div className="flex items-center">
                        <input
                            id="pomodoroTime"
                            type="number"
                            min="1"
                            max="120"
                            value={pomodoroTime}
                            onChange={(e) => setPomodoroTime(e.target.value)}
                            className="form-input w-full rounded-md border-gray-300 shadow-sm"
                        />
                        <span className="ml-4 text-lg text-gray-600">min</span>
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="restingTime">
                        Tiempo de Descanso
                    </label>
                    <div className="flex items-center">
                        <input
                            id="restingTime"
                            type="number"
                            min="1"
                            max="60"
                            value={restingTime}
                            onChange={(e) => setRestingTime(e.target.value)}
                            className="form-input w-full rounded-md border-gray-300 shadow-sm"
                        />
                        <span className="ml-4 text-lg text-gray-600">min</span>
                    </div>
                </div>
                <button
                    onClick={handleStartPractice}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Empezar
                </button>
            </div>
        </div>
    );
};

export default inject('store')(observer(PracticeSetup));
