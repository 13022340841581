import React, { Component } from 'react';
import { inject } from 'mobx-react'


@inject('store')
class EmbedVgpt extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const userId = this.props.store.profile._id;
    const email = this.props.store.profile.email;
    // const organizationName = this.props.store.profile.organization;
    const organizationName = "RicoCorp"
    const applicationName = "VirtusGPT"

    return (
      <div>
        <iframe
          id="virtus-embed"
          src={`https://www.ricocorp.xyz/proxy/virtus?userId=${userId}&email=${email}&organizationName=${organizationName}&applicationName=${applicationName}`}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          title="Virtus GPT"
        ></iframe>
      </div>
    );
  }
}

export default EmbedVgpt;
